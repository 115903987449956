import { NestedOptionProps } from "../helpers/optionsInterface";

export const countryOptions: NestedOptionProps = {
  filterId: "countyId",
  filterTitle: "Velg område",
  options: [
    {
      optionId: "Alle",
      subOptions: [],
    },
    {
      "optionId": "Afghanistan",
      "code": "AF",
      "subOptions": [
        "Badakhshan",
        "Badghis",
        "Baghlan",
        "Balkh",
        "Bamyan",
        "Daykundi",
        "Farah",
        "Faryab",
        "Ghazni",
        "Ghor",
        "Helmand",
        "Herat",
        "Jowzjan",
        "Kabul",
        "Kandahar",
        "Kapisa",
        "Khost",
        "Kunar",
        "Kunduz",
        "Laghman",
        "Logar",
        "Maidan Wardak",
        "Nangarhar",
        "Nimruz",
        "Nuristan",
        "Paktia",
        "Paktika",
        "Panjshir",
        "Parwan",
        "Samangan",
        "Sar-e Pol",
        "Takhar",
        "Urozgan",
        "Zabul"
      ]
    },
    {
      "optionId": "Åland Islands",
      "code": "AX",
      "subOptions": [
        "Brändö",
        "Eckerö",
        "Finström",
        "Föglö",
        "Geta",
        "Hammarland",
        "Jomala",
        "Kumlinge",
        "Kökar",
        "Lemland",
        "Lumparland",
        "Mariehamn",
        "Saltvik",
        "Sottunga",
        "Sund",
        "Vårdö"
      ]
    },
    {
      "optionId": "Albania",
      "code": "AL",
      "subOptions": [
        "Berat",
        "Dibër",
        "Durrës",
        "Elbasan",
        "Fier",
        "Gjirokastër",
        "Korçë",
        "Kukës",
        "Lezhë",
        "Shkodër",
        "Tirana",
        "Vlorë"
      ]
    },
    {
      "optionId": "Algeria",
      "code": "DZ",
      "subOptions": [
        "Adrar",
        "Aïn Defla",
        "Aïn Témouchent",
        "Algiers",
        "Annaba",
        "Batna",
        "Béchar",
        "Béjaïa",
        "Biskra",
        "Blida",
        "Bordj Bou Arréridj",
        "Bouïra",
        "Boumerdès",
        "Chlef",
        "Constantine",
        "Djelfa",
        "El Bayadh",
        "El Oued",
        "El Tarf",
        "Ghardaïa",
        "Guelma",
        "Illizi",
        "Jijel",
        "Khenchela",
        "Laghouat",
        "Mascara",
        "Médéa",
        "Mila",
        "Mostaganem",
        "Msila",
        "Naâma",
        "Oran",
        "Ouargla",
        "Oum el Bouaghi",
        "Relizane",
        "Saïda",
        "Sétif",
        "Sidi Bel Abbès",
        "Skikda",
        "Souk Ahras",
        "Tamanghasset",
        "Tébessa",
        "Tiaret",
        "Tindouf",
        "Tipaza",
        "Tissemsilt",
        "Tizi Ouzou",
        "Tlemcen"
      ]
    },
    {
      "optionId": "American Samoa",
      "code": "AS",
      "subOptions": [
        "Tutuila",
        "Aunu'u",
        "Ta'ū",
        "Ofu‑Olosega",
        "Rose Atoll",
        "Swains Island"
      ]
    },
    {
      "optionId": "Andorra",
      "code": "AD",
      "subOptions": [
        "Andorra la Vella",
        "Canillo",
        "Encamp",
        "Escaldes-Engordany",
        "La Massana",
        "Ordino",
        "Sant Julià de Lòria"
      ]
    },
    {
      "optionId": "Angola",
      "code": "AO",
      "subOptions": [
        "Bengo",
        "Benguela",
        "Bié",
        "Cabinda",
        "Cuando Cubango",
        "Cuanza Norte",
        "Cuanza Sul",
        "Cunene",
        "Huambo",
        "Huíla",
        "Luanda",
        "Lunda Norte",
        "Lunda Sul",
        "Malanje",
        "Moxico",
        "Namibe",
        "Uíge",
        "Zaire"
      ]
    },
    {
      "optionId": "Anguilla",
      "code": "AI",
      "subOptions": [
        "Anguilla",
        "Anguillita Island",
        "Blowing Rock",
        "Cove Cay",
        "Crocus Cay",
        "Deadman's Cay",
        "Dog Island",
        "East Cay",
        "Little Island",
        "Little Scrub Island",
        "Mid Cay",
        "North Cay",
        "Prickly Pear Cays",
        "Rabbit Island",
        "Sandy Island/Sand Island",
        "Scilly Cay",
        "Scrub Island",
        "Seal Island",
        "Sombrero/Hat Island",
        "South Cay",
        "South Wager Island",
        "West Cay"
      ]
    },
    {
      "optionId": "Antarctica",
      "code": "AQ",
      "subOptions": [
        "Antarctica"
      ]
    },
    {
      "optionId": "Antigua and Barbuda",
      "code": "AG",
      "subOptions": [
        "Antigua Island",
        "Barbuda Island",
        "Bird Island",
        "Bishop Island",
        "Blake Island",
        "Crump Island",
        "Dulcina Island",
        "Exchange Island",
        "Five Islands",
        "Great Bird Island",
        "Green Island",
        "Guiana Island",
        "Hawes Island",
        "Hells Gate Island",
        "Henry Island",
        "Johnson Island",
        "Kid Island",
        "Lobster Island",
        "Maiden Island",
        "Moor Island",
        "Nanny Island",
        "Pelican Island",
        "Prickly Pear Island",
        "Rabbit Island",
        "Red Head Island",
        "Redonda Island",
        "Sandy Island",
        "Smith Island",
        "The Sisters",
        "Vernon Island",
        "Wicked Will Island",
        "York Island"
      ]
    },
    {
      "optionId": "Argentina",
      "code": "AR",
      "subOptions": [
        "Buenos Aires",
        "Capital Federal",
        "Catamarca",
        "Chaco",
        "Chubut",
        "Córdoba",
        "Corrientes",
        "Entre Ríos",
        "Formosa",
        "Jujuy",
        "La Pampa",
        "La Rioja",
        "Mendoza",
        "Misiones",
        "Neuquén",
        "Río Negro",
        "Salta",
        "San Juan",
        "San Luis",
        "Santa Cruz",
        "Santa Fe",
        "Santiago del Estero",
        "Tierra del Fuego",
        "Tucumán"
      ]
    },
    {
      "optionId": "Armenia",
      "code": "AM",
      "subOptions": [
        "Aragatsotn",
        "Ararat",
        "Armavir",
        "Gegharkunik",
        "Kotayk",
        "Lori",
        "Shirak",
        "Syunik",
        "Tavush",
        "Vayots Dzor",
        "Yerevan"
      ]
    },
    {
      "optionId": "Aruba",
      "code": "AW",
      "subOptions": [
        "Aruba"
      ]
    },
    {
      "optionId": "Australia",
      "code": "AU",
      "subOptions": [
        "Australian Capital Territory",
        "New South Wales",
        "Northern Territory",
        "Queensland",
        "South Australia",
        "Tasmania",
        "Victoria",
        "Western Australia"
      ]
    },
    {
      "optionId": "Austria",
      "code": "AT",
      "subOptions": [
        "Burgenland",
        "Kärnten",
        "Niederösterreich",
        "Oberösterreich",
        "Salzburg",
        "Steiermark",
        "Tirol",
        "Vorarlberg",
        "Wien"
      ]
    },
    {
      "optionId": "Azerbaijan",
      "code": "AZ",
      "subOptions": [
        "Abşeron",
        "Ağcabədi",
        "Ağdam",
        "Ağdaş",
        "Ağstafa",
        "Ağsu",
        "Astara",
        "Bakı",
        "Babək",
        "Balakən",
        "Bərdə",
        "Beyləqan",
        "Biləsuvar",
        "Cəbrayıl",
        "Cəlilabad",
        "Culfa",
        "Daşkəsən",
        "Füzuli",
        "Gədəbəy",
        "Goranboy",
        "Göyçay",
        "Göygöl",
        "Hacıqabul",
        "İmişli",
        "İsmayıllı",
        "Kəlbəcər",
        "Kǝngǝrli",
        "Kürdəmir",
        "Laçın",
        "Lənkəran",
        "Lerik",
        "Masallı",
        "Neftçala",
        "Oğuz",
        "Ordubad",
        "Qəbələ",
        "Qax",
        "Qazax",
        "Qobustan",
        "Quba",
        "Qubadli",
        "Qusar",
        "Saatlı",
        "Sabirabad",
        "Şabran",
        "Sədərək",
        "Şahbuz",
        "Şəki",
        "Salyan",
        "Şamaxı",
        "Şəmkir",
        "Samux",
        "Şərur",
        "Siyəzən",
        "Şuşa",
        "Tərtər",
        "Tovuz",
        "Ucar",
        "Xaçmaz",
        "Xızı",
        "Xocalı",
        "Xocavənd",
        "Yardımlı",
        "Yevlax",
        "Zəngilan",
        "Zaqatala",
        "Zərdab"
      ]
    },
    {
      "optionId": "Bahamas",
      "code": "BS",
      "subOptions": [
        "Acklins Island",
        "Berry Islands",
        "Bimini",
        "Black Point",
        "Cat Island",
        "Central Abaco",
        "Crooked Island and Long Cay",
        "East Grand Bahama",
        "Exuma",
        "Freeport",
        "Fresh Creek",
        "Governor's Harbour",
        "Green Turtle Cay",
        "Harbour Island",
        "High Rock",
        "Inagua",
        "Kemps Bay",
        "Long Island",
        "Marsh Harbour",
        "Mayaguana",
        "Moore’s Island",
        "New Providence",
        "Nichollstown and Berry Islands",
        "North Abaco",
        "North Andros",
        "North Eleuthera",
        "Ragged Island",
        "Rock Sound",
        "San Salvador and Rum Cay",
        "Sandy Point",
        "South Abaco",
        "South Andros",
        "South Eleuthera",
        "West Grand Bahama"
      ]
    },
    {
      "optionId": "Bahrain",
      "code": "BH",
      "subOptions": [
        "Al Janūbīyah",
        "Al Manāmah",
        "Al Muḩarraq",
        "Al Wusţá",
        "Ash Shamālīyah"
      ]
    },
    {
      "optionId": "Bangladesh",
      "code": "BD",
      "subOptions": [
        "Barisal",
        "Chittagong",
        "Dhaka",
        "Khulna",
        "Mymensingh",
        "Rajshahi",
        "Rangpur",
        "Sylhet"
      ]
    },
    {
      "optionId": "Barbados",
      "code": "BB",
      "subOptions": [
        "Christ Church",
        "Saint Andrew",
        "Saint George",
        "Saint James",
        "Saint John",
        "Saint Joseph",
        "Saint Lucy",
        "Saint Michael",
        "Saint Peter",
        "Saint Philip",
        "Saint Thomas"
      ]
    },
    {
      "optionId": "Belarus",
      "code": "BY",
      "subOptions": [
        "Brest voblast",
        "Gorod Minsk",
        "Homiel voblast",
        "Hrodna voblast",
        "Mahilyow voblast",
        "Minsk voblast",
        "Vitsebsk voblast"
      ]
    },
    {
      "optionId": "Belgium",
      "code": "BE",
      "subOptions": [
        "Antwerpen",
        "Limburg",
        "Oost-Vlaanderen",
        "West-Vlaanderen",
        "Vlaams-Brabant",
        "Waals-Brabant",
        "Henegouwen",
        "Namen",
        "Luik",
        "Luxemburg",
        "Brussel-Hoofdstad"
      ]
    },
    {
      "optionId": "Belize",
      "code": "BZ",
      "subOptions": [
        "Belize District",
        "Cayo District",
        "Corozal District",
        "Orange Walk District",
        "Stann Creek District",
        "Toledo District"
      ]
    },
    {
      "optionId": "Benin",
      "code": "BJ",
      "subOptions": [
        "Alibori",
        "Atakora",
        "Atlantique",
        "Borgou",
        "Collines Department",
        "Donga",
        "Kouffo",
        "Littoral Department",
        "Mono Department",
        "Ouémé",
        "Plateau",
        "Zou"
      ]
    },
    {
      "optionId": "Bermuda",
      "code": "BM",
      "subOptions": [
        "City of Hamilton",
        "Devonshire Parish",
        "Hamilton Parish",
        "Paget Parish",
        "Pembroke Parish",
        "Sandys Parish",
        "Smith's Parish",
        "Southampton Parish",
        "St. George's Parish",
        "Town of St. George",
        "Warwick Parish"
      ]
    },
    {
      "optionId": "Bhutan",
      "code": "BT",
      "subOptions": [
        "Bumthang",
        "Chhukha",
        "Dagana",
        "Gasa",
        "Haa",
        "Lhuntse",
        "Mongar",
        "Paro",
        "Pemagatshel",
        "Punakha",
        "Samdrup Jongkhar",
        "Samtse",
        "Sarpang",
        "Thimphu",
        "Trashigang",
        "Trashiyangtse",
        "Trongsa",
        "Tsirang",
        "Wangdue Phodrang",
        "Zhemgang"
      ]
    },
    {
      "optionId": "Bolivia",
      "code": "BO",
      "subOptions": [
        "Beni",
        "Chuquisaca",
        "Cochabamba",
        "La Paz",
        "Oruro",
        "Pando",
        "Potosí",
        "Santa Cruz",
        "Tarija"
      ]
    },
    {
      "optionId": "Bonaire, Sint Eustatius and Saba",
      "code": "BQ",
      "subOptions": [
        "Bonaire",
        "Saba Isand",
        "Sint Eustatius"
      ]
    },
    {
      "optionId": "Bosnia and Herzegovina",
      "code": "BA",
      "subOptions": [
        "Brčko Distrikt",
        "Federacija Bosne i Hercegovine",
        "Republika Srpska"
      ]
    },
    {
      "optionId": "Botswana",
      "code": "BW",
      "subOptions": [
        "Central",
        "Ghanzi",
        "Kgalagadi",
        "Kgatleng",
        "Kweneng",
        "North West",
        "North-East",
        "South East",
        "Southern"
      ]
    },
    {
      "optionId": "Bouvet Island",
      "code": "BV",
      "subOptions": [
        "Bouvet Island"
      ]
    },
    {
      "optionId": "Brazil",
      "code": "BR",
      "subOptions": [
        "Acre",
        "Alagoas",
        "Amapá",
        "Amazonas",
        "Bahia",
        "Ceará",
        "Distrito Federal",
        "Espírito Santo",
        "Goiás",
        "Maranhão",
        "Mato Grosso",
        "Mato Grosso do Sul",
        "Minas Gerais",
        "Pará",
        "Paraíba",
        "Paraná",
        "Pernambuco",
        "Piauí",
        "Rio de Janeiro",
        "Rio Grande do Norte",
        "Rio Grande do Sul",
        "Rondônia",
        "Roraima",
        "Santa Catarina",
        "São Paulo",
        "Sergipe",
        "Tocantins"
      ]
    },
    {
      "optionId": "British Indian Ocean Territory",
      "code": "IO",
      "subOptions": [
        "British Indian Ocean Territory"
      ]
    },
    {
      "optionId": "Brunei Darussalam",
      "code": "BN",
      "subOptions": [
        "Belait",
        "Brunei Muara",
        "Temburong",
        "Tutong"
      ]
    },
    {
      "optionId": "Bulgaria",
      "code": "BG",
      "subOptions": [
        "Blagoevgrad",
        "Burgas",
        "Dobrich",
        "Gabrovo",
        "Jambol",
        "Khaskovo",
        "Kjustendil",
        "Kurdzhali",
        "Lovech",
        "Montana",
        "Pazardzhik",
        "Pernik",
        "Pleven",
        "Plovdiv",
        "Razgrad",
        "Ruse",
        "Shumen",
        "Silistra",
        "Sliven",
        "Smoljan",
        "Sofija",
        "Sofija-Grad",
        "Stara Zagora",
        "Turgovishhe",
        "Varna",
        "Veliko Turnovo",
        "Vidin",
        "Vraca"
      ]
    },
    {
      "optionId": "Burkina Faso",
      "code": "BF",
      "subOptions": [
        "Balé",
        "Bam/Lake Bam",
        "Banwa Province",
        "Bazèga",
        "Bougouriba",
        "Boulgou Province",
        "Boulkiemdé",
        "Comoé/Komoe",
        "Ganzourgou Province",
        "Gnagna",
        "Gourma Province",
        "Houet",
        "Ioba",
        "Kadiogo",
        "Kénédougou",
        "Komondjari",
        "Kompienga",
        "Kossi Province",
        "Koulpélogo",
        "Kouritenga",
        "Kourwéogo",
        "Léraba",
        "Loroum",
        "Mouhoun",
        "Namentenga",
        "Naouri/Nahouri",
        "Nayala",
        "Noumbiel",
        "Oubritenga",
        "Oudalan",
        "Passoré",
        "Poni",
        "Sanguié",
        "Sanmatenga",
        "Séno",
        "Sissili",
        "Soum",
        "Sourou",
        "Tapoa",
        "Tui/Tuy",
        "Yagha",
        "Yatenga",
        "Ziro",
        "Zondoma",
        "Zoundwéogo"
      ]
    },
    {
      "optionId": "Burundi",
      "code": "BI",
      "subOptions": [
        "Bubanza",
        "Bujumbura Mairie",
        "Bujumbura Rural",
        "Bururi",
        "Cankuzo",
        "Cibitoke",
        "Gitega",
        "Karuzi",
        "Kayanza",
        "Kirundo",
        "Makamba",
        "Muramvya",
        "Muyinga",
        "Mwaro",
        "Ngozi",
        "Rutana",
        "Ruyigi"
      ]
    },
    {
      "optionId": "Cambodia",
      "code": "KH",
      "subOptions": [
        "Baat Dambang",
        "Banteay Mean Chey",
        "Kampong Chaam",
        "Kampong Chhnang",
        "Kampong Spueu",
        "Kampong Thum",
        "Kampot",
        "Kandaal",
        "Kaoh Kong",
        "Kracheh",
        "Krong Kaeb",
        "Krong Pailin",
        "Krong Preah Sihanouk",
        "Mondol Kiri",
        "Otdar Mean Chey",
        "Phnom Penh",
        "Pousaat",
        "Preah Vihear",
        "Prey Veaeng",
        "Rotanah Kiri",
        "Siem Reab",
        "Stueng Treng",
        "Svaay Rieng",
        "Taakaev",
        "Tbong Khmum"
      ]
    },
    {
      "optionId": "Cameroon",
      "code": "CM",
      "subOptions": [
        "Adamaoua",
        "Centre",
        "Est",
        "Extrême-Nord",
        "Littoral",
        "Nord",
        "Nord-Ouest",
        "Ouest",
        "Sud",
        "Sud-Ouest"
      ]
    },
    {
      "optionId": "Canada",
      "code": "CA",
      "subOptions": [
        "Alberta",
        "British Columbia",
        "Manitoba",
        "New Brunswick",
        "Newfoundland and Labrador",
        "Northwest Territories",
        "Nova Scotia",
        "Nunavut",
        "Ontario",
        "Prince Edward Island",
        "Quebec",
        "Saskatchewan",
        "Yukon"
      ]
    },
    {
      "optionId": "Cape Verde",
      "code": "CV",
      "subOptions": [
        "Boa Vista",
        "Brava",
        "Calheta de São Miguel",
        "Maio",
        "Mosteiros",
        "Paúl",
        "Porto Novo",
        "Praia",
        "Ribeira Brava",
        "Ribeira Grande",
        "Sal",
        "Santa Catarina",
        "Santa Cruz",
        "São Domingos",
        "São Filipe",
        "São Nicolau",
        "São Vicente",
        "Tarrafal",
        "Tarrafal de São Nicolau"
      ]
    },
    {
      "optionId": "Cayman Islands",
      "code": "KY",
      "subOptions": [
        "Creek",
        "Eastern",
        "Midland",
        "South Town",
        "Spot Bay",
        "Stake Bay",
        "West End",
        "Western"
      ]
    },
    {
      "optionId": "Central African Republic",
      "code": "CF",
      "subOptions": [
        "Bamingui-Bangoran",
        "Bangui",
        "Basse-Kotto",
        "Haute-Kotto",
        "Haut-Mbomou",
        "Kémo",
        "Lobaye",
        "Mambéré-Kadéï",
        "Mbomou",
        "Nana-Grebizi",
        "Nana-Mambéré",
        "Ombella-M'Poko",
        "Ouaka",
        "Ouham",
        "Ouham Péndé",
        "Sangha-Mbaéré",
        "Vakaga"
      ]
    },
    {
      "optionId": "Chad",
      "code": "TD",
      "subOptions": [
        "Bahr el Ghazal",
        "Batha",
        "Borkou",
        "Chari-Baguirmi",
        "Ennedi-Est",
        "Ennedi-Ouest",
        "Guéra",
        "Hadjer Lamis",
        "Kanem",
        "Lac",
        "Logone Occidental",
        "Logone Oriental",
        "Mondoul",
        "Mayo-Kébbi-Est",
        "Moyen-Chari",
        "Ouaddai",
        "Salamat",
        "Sila",
        "Tandjilé",
        "Tibesti",
        "Ville de Ndjamena",
        "Wadi Fira"
      ]
    },
    {
      "optionId": "Chile",
      "code": "CL",
      "subOptions": [
        "Aisén del General Carlos Ibáñez del Campo",
        "Antofagasta",
        "Araucanía",
        "Arica y Parinacota",
        "Atacama",
        "Bío-Bío",
        "Coquimbo",
        "Libertador General Bernardo O'Higgins",
        "Los Lagos",
        "Los Ríos",
        "Magallanes y Antartica Chilena",
        "Marga-Marga",
        "Maule",
        "Ñuble",
        "Región Metropolitana de Santiago",
        "Tarapacá",
        "Valparaíso"
      ]
    },
    {
      "optionId": "China",
      "code": "CN",
      "subOptions": [
        "Anhui",
        "Beijing",
        "Chongqing",
        "Fujian",
        "Gansu",
        "Guangdong",
        "Guangxi",
        "Guizhou",
        "Hainan",
        "Hebei",
        "Heilongjiang",
        "Henan",
        "Hong Kong",
        "Hubei",
        "Hunan",
        "Inner Mongolia",
        "Jiangsu",
        "Jiangxi",
        "Jilin",
        "Liaoning",
        "Macau",
        "Ningxia",
        "Qinghai",
        "Shaanxi",
        "Shandong",
        "Shanghai",
        "Shanxi",
        "Sichuan",
        "Tianjin",
        "Tibet",
        "Xinjiang",
        "Yunnan",
        "Zhejiang"
      ]
    },
    {
      "optionId": "Christmas Island",
      "code": "CX",
      "subOptions": [
        "Christmas Island"
      ]
    },
    {
      "optionId": "Cocos (Keeling) Islands",
      "code": "CC",
      "subOptions": [
        "Direction Island",
        "Home Island",
        "Horsburgh Island",
        "North Keeling Island",
        "South Island",
        "West Island"
      ]
    },
    {
      "optionId": "Colombia",
      "code": "CO",
      "subOptions": [
        "Amazonas",
        "Antioquia",
        "Arauca",
        "Archipiélago de San Andrés",
        "Atlántico",
        "Bogotá D.C.",
        "Bolívar",
        "Boyacá",
        "Caldas",
        "Caquetá",
        "Casanare",
        "Cauca",
        "Cesar",
        "Chocó",
        "Córdoba",
        "Cundinamarca",
        "Guainía",
        "Guaviare",
        "Huila",
        "La Guajira",
        "Magdalena",
        "Meta",
        "Nariño",
        "Norte de Santander",
        "Putumayo",
        "Quindío",
        "Risaralda",
        "Santander",
        "Sucre",
        "Tolima",
        "Valle del Cauca",
        "Vaupés",
        "Vichada"
      ]
    },
    {
      "optionId": "Comoros",
      "code": "KM",
      "subOptions": [
        "Andjazîdja",
        "Andjouân",
        "Moûhîlî"
      ]
    },
    {
      "optionId": "Congo, Republic of the (Brazzaville)",
      "code": "CG",
      "subOptions": [
        "Bouenza",
        "Brazzaville",
        "Cuvette",
        "Cuvette-Ouest",
        "Kouilou",
        "Lékoumou",
        "Likouala",
        "Niari",
        "Plateaux",
        "Pointe-Noire",
        "Pool",
        "Sangha"
      ]
    },
    {
      "optionId": "Congo, the Democratic Republic of the (Kinshasa)",
      "code": "CD",
      "subOptions": [
        "Bandundu",
        "Bas-Congo",
        "Équateur",
        "Kasaï-Occidental",
        "Kasaï-Oriental",
        "Katanga",
        "Kinshasa",
        "Maniema",
        "Nord-Kivu",
        "Orientale",
        "Sud-Kivu"
      ]
    },
    {
      "optionId": "Cook Islands",
      "code": "CK",
      "subOptions": [
        "Aitutaki",
        "Atiu",
        "Avarua",
        "Mangaia",
        "Manihiki",
        "Ma'uke",
        "Mitiaro",
        "Nassau",
        "Palmerston",
        "Penrhyn",
        "Pukapuka",
        "Rakahanga"
      ]
    },
    {
      "optionId": "Costa Rica",
      "code": "CR",
      "subOptions": [
        "Alajuela",
        "Cartago",
        "Guanacaste",
        "Heredia",
        "Limón",
        "Puntarenas",
        "San José"
      ]
    },
    {
      "optionId": "Côte d'Ivoire, Republic of",
      "code": "CI",
      "subOptions": [
        "Agnéby",
        "Bafing",
        "Bas-Sassandra",
        "Denguélé",
        "Dix-Huit Montagnes",
        "Fromager",
        "Haut-Sassandra",
        "Lacs",
        "Lagunes",
        "Marahoué",
        "Moyen-Cavally",
        "Moyen-Comoé",
        "N'zi-Comoé",
        "Savanes",
        "Sud-Bandama",
        "Sud-Comoé",
        "Vallée du Bandama",
        "Worodougou",
        "Zanzan"
      ]
    },
    {
      "optionId": "Croatia",
      "code": "HR",
      "subOptions": [
        "Istarska",
        "Ličko-senjska",
        "Primorsko-goranska",
        "Bjelovarsko-bilogorska",
        "Karlovačka",
        "Koprivničko-križevačka",
        "Krapinsko-zagorska",
        "Međimurska",
        "Sisačko-moslavačka",
        "Varaždinska",
        "Zagrebačka ",
        "Grad Zagreb",
        "Dubrovačko-neretvanska",
        "Splitsko-dalmatinska",
        "Šibensko-kninska",
        "Zadarska",
        "Brodsko-posavska",
        "Osječko-baranjska",
        "Požeško-slavonska",
        "Virovitičko-podravska",
        "Vukovarsko-srijemska"
      ]
    },
    {
      "optionId": "Cuba",
      "code": "CU",
      "subOptions": [
        "Artemisa",
        "Camagüey",
        "Ciego de Ávila",
        "Cienfuegos",
        "Granma",
        "Guantánamo",
        "Holguín",
        "Isla de la Juventud",
        "La Habana",
        "Las Tunas",
        "Matanzas",
        "Mayabeque",
        "Pinar del Río",
        "Sancti Spíritus",
        "Santiago de Cuba",
        "Villa Clara"
      ]
    },
    {
      "optionId": "Curaçao",
      "code": "CW",
      "subOptions": [
        "Curaçao"
      ]
    },
    {
      "optionId": "Cyprus",
      "code": "CY",
      "subOptions": [
        "Ammochostos",
        "Keryneia",
        "Larnaka",
        "Lefkosia",
        "Lemesos",
        "Pafos"
      ]
    },
    {
      "optionId": "Czech Republic",
      "code": "CZ",
      "subOptions": [
        "Jihomoravský kraj",
        "Jihočeský kraj",
        "Karlovarský kraj",
        "Královéhradecký kraj",
        "Liberecký kraj",
        "Moravskoslezský kraj",
        "Olomoucký kraj",
        "Pardubický kraj",
        "Plzeňský kraj",
        "Praha, hlavní město",
        "Středočeský kraj",
        "Vysočina",
        "Zlínský kraj",
        "Ústecký kraj"
      ]
    },
    {
      "optionId": "Denmark",
      "code": "DK",
      "subOptions": [
        "Bornholm",
        "Fyn",
        "Hovedstaden",
        "Nordjylland",
        "Midtjylland",
        "Sjælland",
        "Syddanmark"
      ]
    },
    {
      "optionId": "Djibouti",
      "code": "DJ",
      "subOptions": [
        "Ali Sabieh",
        "Arta",
        "Dikhil",
        "Obock",
        "Tadjourah"
      ]
    },
    {
      "optionId": "Dominica",
      "code": "DM",
      "subOptions": [
        "Saint Andrew Parish",
        "Saint David Parish",
        "Saint George Parish",
        "Saint John Parish",
        "Saint Joseph Parish",
        "Saint Luke Parish",
        "Saint Mark Parish",
        "Saint Patrick Parish",
        "Saint Paul Parish",
        "Saint Peter Parish"
      ]
    },
    {
      "optionId": "Dominican Republic",
      "code": "DO",
      "subOptions": [
        "Cibao Central",
        "Del Valle",
        "Distrito Nacional",
        "Enriquillo",
        "Norcentral",
        "Nordeste",
        "Noroeste",
        "Norte",
        "Valdesia"
      ]
    },
    {
      "optionId": "Ecuador",
      "code": "EC",
      "subOptions": [
        "Azuay",
        "Bolívar",
        "Cañar",
        "Carchi",
        "Chimborazo",
        "Cotopaxi",
        "El Oro",
        "Esmeraldas",
        "Galápagos",
        "Guayas",
        "Imbabura",
        "Loja",
        "Los Ríos",
        "Manabí",
        "Morona-Santiago",
        "Napo",
        "Orellana",
        "Pastaza",
        "Pichincha",
        "Santa Elena",
        "Santo Domingo de los Tsáchilas",
        "Sucumbíos",
        "Tungurahua",
        "Zamora-Chinchipe"
      ]
    },
    {
      "optionId": "Egypt",
      "code": "EG",
      "subOptions": [
        "Alexandria",
        "Aswan",
        "Asyout",
        "Bani Sueif",
        "Beheira",
        "Cairo",
        "Daqahlia",
        "Dumiat",
        "El Bahr El Ahmar",
        "El Ismailia",
        "El Suez",
        "El Wadi El Gedeed",
        "Fayoum",
        "Gharbia",
        "Giza",
        "Helwan",
        "Kafr El Sheikh",
        "Luxor",
        "Matrouh",
        "Menia",
        "Menofia",
        "North Sinai",
        "Port Said",
        "Qalubia",
        "Qena",
        "Sharqia",
        "Sixth of October",
        "Sohag",
        "South Sinai"
      ]
    },
    {
      "optionId": "El Salvador",
      "code": "SV",
      "subOptions": [
        "Ahuachapán",
        "Cabañas",
        "Cuscatlán",
        "Chalatenango",
        "La Libertad",
        "La Paz",
        "La Unión",
        "Morazán",
        "San Miguel",
        "San Salvador",
        "Santa Ana",
        "San Vicente",
        "Sonsonate",
        "Usulután"
      ]
    },
    {
      "optionId": "Equatorial Guinea",
      "code": "GQ",
      "subOptions": [
        "Annobón",
        "Bioko Norte",
        "Bioko Sur",
        "Centro Sur",
        "Kié-Ntem",
        "Litoral",
        "Wele-Nzas"
      ]
    },
    {
      "optionId": "Eritrea",
      "code": "ER",
      "subOptions": [
        "Anseba",
        "Debub",
        "Debub-Keih-Bahri",
        "Gash-Barka",
        "Maekel",
        "Semien-Keih-Bahri"
      ]
    },
    {
      "optionId": "Estonia",
      "code": "EE",
      "subOptions": [
        "Harjumaa (Tallinn)",
        "Hiiumaa (Kardla)",
        "Ida-Virumaa (Johvi)",
        "Järvamaa (Paide)",
        "Jõgevamaa (Jogeva)",
        "Läänemaa",
        "Lääne-Virumaa (Rakvere)",
        "Pärnumaa (Parnu)",
        "Põlvamaa (Polva)",
        "Raplamaa (Rapla)",
        "Saaremaa (Kuessaare)",
        "Tartumaa (Tartu)",
        "Valgamaa (Valga)",
        "Viljandimaa (Viljandi)",
        "Võrumaa (Voru)"
      ]
    },
    {
      "optionId": "Ethiopia",
      "code": "ET",
      "subOptions": [
        "Addis Ababa",
        "Afar",
        "Amhara",
        "Benshangul-Gumaz",
        "Dire Dawa",
        "Gambela",
        "Harari",
        "Oromia",
        "Somali",
        "Southern Nations Nationalities and People's Region",
        "Tigray"
      ]
    },
    {
      "optionId": "Falkland Islands (Islas Malvinas)",
      "code": "FK",
      "subOptions": [
        "Falkland Islands (Islas Malvinas)"
      ]
    },
    {
      "optionId": "Faroe Islands",
      "code": "FO",
      "subOptions": [
        "Norðoyar",
        "Eysturoy",
        "Norðurstreymoy",
        "Suðurstreymoy",
        "Vágar",
        "Sandoy",
        "Suðuroy"
      ]
    },
    {
      "optionId": "Fiji",
      "code": "FJ",
      "subOptions": [
        "Ba",
        "Bua",
        "Cakaudrove",
        "Kadavu",
        "Lau",
        "Lomaiviti",
        "Macuata",
        "Nadroga and Navosa",
        "Naitasiri",
        "Namosi",
        "Ra",
        "Rewa",
        "Rotuma",
        "Serua",
        "Tailevu"
      ]
    },
    {
      "optionId": "Finland",
      "code": "FI",
      "subOptions": [
        "Varsinais-Suomi",
        "Uusimaa",
        "Kymenlaakso",
        "Etelä-Karjala",
        "Kanta-Häme",
        "Päijät-Häme",
        "Etelä-Savo",
        "Satakunta",
        "Pirkanmaa",
        "Keski-Suomi",
        "Pohjois-Savo",
        "Pohjois-Karjala",
        "Pohjanmaa",
        "Etelä-Pohjanmaa",
        "Keski-Pohjanmaa",
        "Pohjois-Pohjanmaa",
        "Kainuu",
        "Lappi",
        "Ahvenanmaa"
      ]
    },
    {
      "optionId": "France",
      "code": "FR",
      "subOptions": [
        "Auvergne-Rhône-Alpes",
        "Bourgogne-Franche-Comté",
        "Bretagne",
        "Centre-Val de Loire",
        "Corse",
        "Grand Est",
        "Hauts-de-France",
        "Île-de-France",
        "Normandie",
        "Nouvelle-Aquitaine",
        "Occitanie",
        "Pays de la Loire",
        "Provence-Alpes-Côte d'Azur",
        "Clipperton",
        "Guadeloupe",
        "Guyane",
        "Martinique",
        "Mayotte",
        "Nouvelle-Calédonie",
        "Polynésie",
        "Saint-Pierre-et-Miquelon",
        "Saint Barthélemy",
        "Saint Martin",
        "Réunion",
        "Terres Australes Françaises",
        "Wallis-et-Futuna"
      ]
    },
    {
      "optionId": "French Guiana",
      "code": "GF",
      "subOptions": [
        "French Guiana"
      ]
    },
    {
      "optionId": "French Polynesia",
      "code": "PF",
      "subOptions": [
        "Archipel des Marquises",
        "Archipel des Tuamotu",
        "Archipel des Tubuai",
        "Iles du Vent",
        "Iles Sous-le-Vent"
      ]
    },
    {
      "optionId": "French Southern and Antarctic Lands",
      "code": "TF",
      "subOptions": [
        "Adelie Land",
        "Ile Crozet",
        "Iles Kerguelen",
        "Iles Saint-Paul et Amsterdam"
      ]
    },
    {
      "optionId": "Gabon",
      "code": "GA",
      "subOptions": [
        "Estuaire",
        "Haut-Ogooué",
        "Moyen-Ogooué",
        "Ngounié",
        "Nyanga",
        "Ogooué-Ivindo",
        "Ogooué-Lolo",
        "Ogooué-Maritime",
        "Woleu-Ntem"
      ]
    },
    {
      "optionId": "Gambia, The",
      "code": "GM",
      "subOptions": [
        "Banjul",
        "Central River",
        "Lower River",
        "North Bank",
        "Upper River",
        "Western"
      ]
    },
    {
      "optionId": "Georgia",
      "code": "GE",
      "subOptions": [
        "Abkhazia (Sokhumi)",
        "Ajaria (Bat'umi)",
        "Guria",
        "Imereti",
        "K'akheti",
        "Kvemo Kartli",
        "Mtshkheta-Mtianeti",
        "Rach'a-Lexhkumi-KvemoSvaneti",
        "Samegrelo-Zemo Svaneti",
        "Samtskhe-Javakheti",
        "Shida Kartli",
        "Tbilisi"
      ]
    },
    {
      "optionId": "Germany",
      "code": "DE",
      "subOptions": [
        "Baden-Württemberg",
        "Bayern",
        "Berlin",
        "Brandenburg",
        "Bremen",
        "Hamburg",
        "Hessen",
        "Mecklenburg-Vorpommern",
        "Niedersachsen",
        "Nordrhein-Westfalen",
        "Rheinland-Pfalz",
        "Saarland",
        "Sachsen",
        "Sachsen-Anhalt",
        "Schleswig-Holstein",
        "Thüringen"
      ]
    },
    {
      "optionId": "Ghana",
      "code": "GH",
      "subOptions": [
        "Ahafo",
        "Ashanti",
        "Bono",
        "Bono East",
        "Central",
        "Eastern",
        "Greater Accra",
        "Northern",
        "North East",
        "Oti",
        "Savannah",
        "Upper East",
        "Upper West",
        "Volta",
        "Western",
        "Western North"
      ]
    },
    {
      "optionId": "Gibraltar",
      "code": "GI",
      "subOptions": [
        "Gibraltar"
      ]
    },
    {
      "optionId": "Greece",
      "code": "GR",
      "subOptions": [
        "Anatolikí Makedonía kai Thráki",
        "Attikḯ",
        "Dytikí Elláda",
        "Dytikí Makedonía",
        "Ionía Nísia",
        "Kentrikí Makedonía",
        "Krítí",
        "Notío Aigaío",
        "Peloponnísos",
        "Stereá Elláda",
        "Thessalía",
        "Voreío Aigaío",
        "Ípeiros",
        "Ágion Óros"
      ]
    },
    {
      "optionId": "Greenland",
      "code": "GL",
      "subOptions": [
        "Kommune Kujalleq",
        "Kommuneqarfik Sermersooq",
        "Qaasuitsup Kommunia",
        "Qeqqata Kommunia"
      ]
    },
    {
      "optionId": "Grenada",
      "code": "GD",
      "subOptions": [
        "Saint Andrew",
        "Saint David",
        "Saint George",
        "Saint John",
        "Saint Mark",
        "Saint Patrick",
        "Southern Grenadine Islands"
      ]
    },
    {
      "optionId": "Guadeloupe",
      "code": "GP",
      "subOptions": [
        "Guadeloupe"
      ]
    },
    {
      "optionId": "Guam",
      "code": "GU",
      "subOptions": [
        "Guam"
      ]
    },
    {
      "optionId": "Guatemala",
      "code": "GT",
      "subOptions": [
        "Alta Verapaz",
        "Baja Verapaz",
        "Chimaltenango",
        "Chiquimula",
        "El Progreso",
        "Escuintla",
        "Guatemala",
        "Huehuetenango",
        "Izabal",
        "Jalapa",
        "Jutiapa",
        "Petén",
        "Quetzaltenango",
        "Quiché",
        "Retalhuleu",
        "Sacatepéquez",
        "San Marcos",
        "Santa Rosa",
        "Sololá",
        "Suchitepéquez",
        "Totonicapán",
        "Zacapa"
      ]
    },
    {
      "optionId": "Guernsey",
      "code": "GG",
      "subOptions": [
        "Castel",
        "Forest",
        "St. Andrew",
        "St. Martin",
        "St. Peter Port",
        "St. Pierre du Bois",
        "St. Sampson",
        "St. Saviour",
        "Torteval",
        "Vale"
      ]
    },
    {
      "optionId": "Guinea",
      "code": "GN",
      "subOptions": [
        "Boké",
        "Conakry",
        "Faranah",
        "Kankan",
        "Kindia",
        "Labé",
        "Mamou",
        "Nzérékoré"
      ]
    },
    {
      "optionId": "Guinea-Bissau",
      "code": "GW",
      "subOptions": [
        "Bafatá",
        "Biombo",
        "Bissau",
        "Bolama-Bijagos",
        "Cacheu",
        "Gabú",
        "Oio",
        "Quinara",
        "Tombali"
      ]
    },
    {
      "optionId": "Guyana",
      "code": "GY",
      "subOptions": [
        "Barima-Waini",
        "Cuyuni-Mazaruni",
        "Demerara-Mahaica",
        "East Berbice-Corentyne",
        "Essequibo Islands-West Demerara",
        "Mahaica-Berbice",
        "Pomeroon-Supenaam",
        "Potaro-Siparuni",
        "Upper Demerara-Berbice",
        "Upper Takutu-Upper Essequibo"
      ]
    },
    {
      "optionId": "Haiti",
      "code": "HT",
      "subOptions": [
        "Artibonite",
        "Centre",
        "Grand'Anse",
        "Nippes",
        "Nord",
        "Nord-Est",
        "Nord-Ouest",
        "Ouest",
        "Sud",
        "Sud-Est"
      ]
    },
    {
      "optionId": "Heard Island and McDonald Islands",
      "code": "HM",
      "subOptions": [
        "Heard Island and McDonald Islands"
      ]
    },
    {
      "optionId": "Holy See (Vatican City)",
      "code": "VA",
      "subOptions": [
        "Holy See (Vatican City)"
      ]
    },
    {
      "optionId": "Honduras",
      "code": "HN",
      "subOptions": [
        "Atlántida",
        "Choluteca",
        "Colón",
        "Comayagua",
        "Copán",
        "Cortés",
        "El Paraíso",
        "Francisco Morazán",
        "Gracias a Dios",
        "Intibucá",
        "Islas de la Bahía",
        "La Paz",
        "Lempira",
        "Ocotepeque",
        "Olancho",
        "Santa Bárbara",
        "Valle",
        "Yoro"
      ]
    },
    {
      "optionId": "Hong Kong",
      "code": "HK",
      "subOptions": [
        "Hong Kong"
      ]
    },
    {
      "optionId": "Hungary",
      "code": "HU",
      "subOptions": [
        "Baranya",
        "Bács-Kiskun",
        "Békés",
        "Borsod-Abaúj-Zemplén",
        "Budapest",
        "Csongrád-Csanád",
        "Fejér",
        "Győr-Moson-Sopron",
        "Hajdú-Bihar",
        "Heves",
        "Jász-Nagykun-Szolnok",
        "Komárom-Esztergom",
        "Nógrád",
        "Pest",
        "Somogy",
        "Szabolcs-Szatmár-Bereg",
        "Tolna",
        "Vas",
        "Veszprém",
        "Zala"
      ]
    },
    {
      "optionId": "Iceland",
      "code": "IS",
      "subOptions": [
        "Höfuðborgarsvæðið",
        "Suðurnes",
        "Vesturland",
        "Vestfirðir",
        "Norðurland vestra",
        "Norðurland eystra",
        "Austurland",
        "Suðurland"
      ]
    },
    {
      "optionId": "India",
      "code": "IN",
      "subOptions": [
        "Andaman and Nicobar Islands",
        "Andhra Pradesh",
        "Arunachal Pradesh",
        "Assam",
        "Bihar",
        "Chandigarh",
        "Chhattisgarh",
        "Dadra and Nagar Haveli and Daman and Diu",
        "Delhi",
        "Goa",
        "Gujarat",
        "Haryana",
        "Himachal Pradesh",
        "Jammu and Kashmir",
        "Jharkhand",
        "Karnataka",
        "Kerala",
        "Ladakh",
        "Lakshadweep",
        "Madhya Pradesh",
        "Maharashtra",
        "Manipur",
        "Meghalaya",
        "Mizoram",
        "Nagaland",
        "Odisha",
        "Puducherry",
        "Punjab",
        "Rajasthan",
        "Sikkim",
        "Tamil Nadu",
        "Telangana",
        "Tripura",
        "Uttarakhand",
        "Uttar Pradesh",
        "West Bengal"
      ]
    },
    {
      "optionId": "Indonesia",
      "code": "ID",
      "subOptions": [
        "Aceh",
        "Bali",
        "Bangka Belitung",
        "Banten",
        "Bengkulu",
        "Gorontalo",
        "Jakarta Raya",
        "Jambi",
        "Jawa Barat",
        "Jawa Tengah",
        "Jawa Timur",
        "Kalimantan Barat",
        "Kalimantan Selatan",
        "Kalimantan Tengah",
        "Kalimantan Timur",
        "Kalimantan Utara",
        "Kepulauan Riau",
        "Lampung",
        "Maluku",
        "Maluku Utara",
        "Nusa Tenggara Barat",
        "Nusa Tenggara Timur",
        "Papua",
        "Papua Barat",
        "Riau",
        "Sulawesi Selatan",
        "Sulawesi Tengah",
        "Sulawesi Tenggara",
        "Sulawesi Utara",
        "Sumatera Barat",
        "Sumatera Selatan",
        "Sumatera Utara",
        "Yogyakarta"
      ]
    },
    {
      "optionId": "Iran, Islamic Republic of",
      "code": "IR",
      "subOptions": [
        "Alborz",
        "Ardabīl",
        "Āz̄arbāyjān-e Gharbī",
        "Āz̄arbāyjān-e Sharqī",
        "Būshehr",
        "Chahār Maḩāl va Bakhtīārī",
        "Eşfahān",
        "Fārs",
        "Gīlān",
        "Golestān",
        "Hamadān",
        "Hormozgān",
        "Īlām",
        "Kermān",
        "Kermānshāh",
        "Khorāsān-e Jonūbī",
        "Khorāsān-e Raẕavī",
        "Khorāsān-e Shomālī",
        "Khūzestān",
        "Kohgīlūyeh va Bowyer Aḩmad",
        "Kordestān",
        "Lorestān",
        "Markazi",
        "Māzandarān",
        "Qazvīn",
        "Qom",
        "Semnān",
        "Sīstān va Balūchestān",
        "Tehrān",
        "Yazd",
        "Zanjān"
      ]
    },
    {
      "optionId": "Iraq",
      "code": "IQ",
      "subOptions": [
        "Al Anbār",
        "Al Başrah",
        "Al Muthanná",
        "Al Qādisīyah",
        "An Najaf",
        "Arbīl",
        "As Sulaymānīyah",
        "Bābil",
        "Baghdād",
        "Dohuk",
        "Dhī Qār",
        "Diyālá",
        "Karbalā'",
        "Kirkuk",
        "Maysān",
        "Nīnawá",
        "Şalāḩ ad Dīn",
        "Wāsiţ"
      ]
    },
    {
      "optionId": "Ireland",
      "code": "IE",
      "subOptions": [
        "Connacht",
        "Leinster",
        "Munster",
        "Ulster"
      ]
    },
    {
      "optionId": "Isle of Man",
      "code": "IM",
      "subOptions": [
        "Isle of Man"
      ]
    },
    {
      "optionId": "Israel",
      "code": "IL",
      "subOptions": [
        "Mehoz Yerushalayim",
        "Mehoz HaTzafon",
        "Mehoz Heifa",
        "Mehoz HaMerkaz",
        "Mehoz Tel Aviv",
        "Mehoz HaDarom"
      ]
    },
    {
      "optionId": "Italy",
      "code": "IT",
      "subOptions": [
        "Abruzzo",
        "Basilicata",
        "Calibria",
        "Campania",
        "Emilia-Romagna",
        "Friûl-Vignesie Julie",
        "Lazio",
        "Liguria",
        "Lombardia",
        "Marche",
        "Molise",
        "Piedmont",
        "Puglia",
        "Sardegna",
        "Sicilia",
        "Trentino-Alto Adige/Südtirol",
        "Toscana",
        "Umbria",
        "Valle d'Aosta",
        "Veneto"
      ]
    },
    {
      "optionId": "Jamaica",
      "code": "JM",
      "subOptions": [
        "Clarendon",
        "Hanover",
        "Kingston",
        "Manchester",
        "Portland",
        "Saint Andrew",
        "Saint Ann",
        "Saint Catherine",
        "Saint Elizabeth",
        "Saint James",
        "Saint Mary",
        "Saint Thomas",
        "Trelawny",
        "Westmoreland"
      ]
    },
    {
      "optionId": "Japan",
      "code": "JP",
      "subOptions": [
        "Aichi",
        "Akita",
        "Aomori",
        "Chiba",
        "Ehime",
        "Fukui",
        "Fukuoka",
        "Fukushima",
        "Gifu",
        "Gunma",
        "Hiroshima",
        "Hokkaido",
        "Hyogo",
        "Ibaraki",
        "Ishikawa",
        "Iwate",
        "Kagawa",
        "Kagoshima",
        "Kanagawa",
        "Kochi",
        "Kumamoto",
        "Kyoto",
        "Mie",
        "Miyagi",
        "Miyazaki",
        "Nagano",
        "Nagasaki",
        "Nara",
        "Niigata",
        "Oita",
        "Okayama",
        "Okinawa",
        "Osaka",
        "Saga",
        "Saitama",
        "Shiga",
        "Shimane",
        "Shizuoka",
        "Tochigi",
        "Tokushima",
        "Tokyo",
        "Tottori",
        "Toyama",
        "Wakayama",
        "Yamagata",
        "Yamaguchi",
        "Yamanashi"
      ]
    },
    {
      "optionId": "Jersey",
      "code": "JE",
      "subOptions": [
        "Jersey"
      ]
    },
    {
      "optionId": "Jordan",
      "code": "JO",
      "subOptions": [
        "‘Ajlūn",
        "Al 'Aqabah",
        "Al Balqā’",
        "Al Karak",
        "Al Mafraq",
        "Al ‘A̅şimah",
        "Aţ Ţafīlah",
        "Az Zarqā’",
        "Irbid",
        "Jarash",
        "Ma‘ān",
        "Mādabā"
      ]
    },
    {
      "optionId": "Kazakhstan",
      "code": "KZ",
      "subOptions": [
        "Almaty",
        "Aqmola",
        "Aqtobe",
        "Astana",
        "Atyrau",
        "Batys Qazaqstan",
        "Bayqongyr",
        "Mangghystau",
        "Ongtustik Qazaqstan",
        "Pavlodar",
        "Qaraghandy",
        "Qostanay",
        "Qyzylorda",
        "Shyghys Qazaqstan",
        "Soltustik Qazaqstan",
        "Zhambyl"
      ]
    },
    {
      "optionId": "Kenya",
      "code": "KE",
      "subOptions": [
        "Baringo",
        "Bomet",
        "Bungoma",
        "Busia",
        "Eleyo/Marakwet",
        "Embu",
        "Garissa",
        "Homa Bay",
        "Isiolo",
        "Kajiado",
        "Kakamega",
        "Kericho",
        "Kiambu",
        "Kilifi",
        "Kirinyaga",
        "Kisii",
        "Kisumu",
        "Kitui",
        "Kwale",
        "Laikipia",
        "Lamu",
        "Machakos",
        "Makueni",
        "Mandera",
        "Marsabit",
        "Meru",
        "Migori",
        "Mombasa",
        "Murang'a",
        "Nairobi City",
        "Nakuru",
        "Nandi",
        "Narok",
        "Nyamira",
        "Nyandarua",
        "Nyeri",
        "Samburu",
        "Siaya",
        "Taita/Taveta",
        "Tana River",
        "Tharaka-Nithi",
        "Trans Nzoia",
        "Turkana",
        "Uasin Gishu",
        "Vihiga",
        "Wajir",
        "West Pokot"
      ]
    },
    {
      "optionId": "Kiribati",
      "code": "KI",
      "subOptions": [
        "Abaiang",
        "Abemama",
        "Aranuka",
        "Arorae",
        "Banaba",
        "Beru",
        "Butaritari",
        "Central Gilberts",
        "Gilbert Islands",
        "Kanton",
        "Kiritimati",
        "Kuria",
        "Line Islands",
        "Maiana",
        "Makin",
        "Marakei",
        "Nikunau",
        "Nonouti",
        "Northern Gilberts",
        "Onotoa",
        "Phoenix Islands",
        "Southern Gilberts",
        "Tabiteuea",
        "Tabuaeran",
        "Tamana",
        "Tarawa",
        "Teraina"
      ]
    },
    {
      "optionId": "Korea, Democratic People's Republic of",
      "code": "KP",
      "subOptions": [
        "Chagang-do (Chagang Province)",
        "Hamgyong-bukto (North Hamgyong Province)",
        "Hamgyong-namdo (South Hamgyong Province)",
        "Hwanghae-bukto (North Hwanghae Province)",
        "Hwanghae-namdo (South Hwanghae Province)",
        "Kangwon-do (Kangwon Province)",
        "Nasŏn (Najin-Sŏnbong)",
        "P'yongan-bukto (North P'yongan Province)",
        "P'yongan-namdo (South P'yongan Province)",
        "P'yongyang-si (P'yongyang City)",
        "Yanggang-do (Yanggang Province)"
      ]
    },
    {
      "optionId": "Korea, Republic of",
      "code": "KR",
      "subOptions": [
        "Chungcheongbuk-do",
        "Chungcheongnam-do",
        "Jeju-teukbyeoljachido",
        "Jeollabuk-do",
        "Jeollanam-do",
        "Incheon-gwangyeoksi",
        "Gangwon-do",
        "Gwangju-gwangyeoksi",
        "Gyeonggi-do",
        "Gyeongsangbuk-do",
        "Gyeongsangnam-do",
        "Busan-gwangyeoksi",
        "Seoul-teukbyeolsi",
        "Sejong-teukbyeoljachisi",
        "Daegu-gwangyeoksi",
        "Daejeon-gwangyeoksi",
        "Ulsan-gwangyeoksi"
      ]
    },
    {
      "optionId": "Kosovo",
      "code": "XK",
      "subOptions": [
        "Farizaj",
        "Gjakova",
        "Gjilan",
        "Mitrovica",
        "Peja/Peć",
        "Pristina",
        "Prizren"
      ]
    },
    {
      "optionId": "Kuwait",
      "code": "KW",
      "subOptions": [
        "Al Aḩmadi",
        "Al Farwānīyah",
        "Al Jahrā’",
        "Al ‘Āşimah",
        "Ḩawallī",
        "Mubārak al Kabir"
      ]
    },
    {
      "optionId": "Kyrgyzstan",
      "code": "KG",
      "subOptions": [
        "Batken Oblasty",
        "Bishkek Shaary",
        "Chuy Oblasty (Bishkek)",
        "Jalal-Abad Oblasty",
        "Naryn Oblasty",
        "Osh Oblasty",
        "Talas Oblasty",
        "Ysyk-Kol Oblasty (Karakol)"
      ]
    },
    {
      "optionId": "Laos",
      "code": "LA",
      "subOptions": [
        "Attapu",
        "Bokèo",
        "Bolikhamxai",
        "Champasak",
        "Houaphan",
        "Khammouan",
        "Louang Namtha",
        "Louangphabang",
        "Oudômxai",
        "Phôngsali",
        "Salavan",
        "Savannakhét",
        "Vientiane",
        "Xaignabouli",
        "Xékong",
        "Xaisomboun",
        "Xiangkhouang"
      ]
    },
    {
      "optionId": "Latvia",
      "code": "LV",
      "subOptions": [
        "Aglona",
        "Aizkraukle",
        "Aizpute",
        "Aknīste",
        "Aloja",
        "Alsunga",
        "Alūksne",
        "Amata",
        "Ape",
        "Auce",
        "Ādaži",
        "Babīte",
        "Baldone",
        "Baltinava",
        "Balvi",
        "Bauska",
        "Beverīna",
        "Brocēni",
        "Burtnieki",
        "Carnikava",
        "Cesvaine",
        "Cēsis",
        "Cibla",
        "Dagda",
        "Daugavpils",
        "Daugavpils (City)",
        "Dobele",
        "Dundaga",
        "Durbe",
        "Engure",
        "Ērgļi",
        "Garkalne",
        "Grobiņa",
        "Gulbene",
        "Iecava",
        "Ikšķile",
        "Ilūkste",
        "Inčukalns",
        "Jaunjelgava",
        "Jaunpiebalga",
        "Jaunpils",
        "Jelgava",
        "Jelgava (City)",
        "Jēkabpils",
        "Jēkabpils (City)",
        "Jūrmala (City)",
        "Kandava",
        "Kārsava",
        "Kocēni",
        "Koknese",
        "Krāslava",
        "Krimulda",
        "Krustpils",
        "Kuldīga",
        "Ķegums",
        "Ķekava",
        "Lielvārde",
        "Liepāja",
        "Limbaži",
        "Līgatne",
        "Līvāni",
        "Lubāna",
        "Ludza",
        "Madona",
        "Mazsalaca",
        "Mālpils",
        "Mārupe",
        "Mērsrags",
        "Naukšēni",
        "Nereta",
        "Nīca",
        "Ogre",
        "Olaine",
        "Ozolnieki",
        "Pārgauja",
        "Pāvilosta",
        "Pļaviņas",
        "Preiļi",
        "Priekule",
        "Priekuļi",
        "Rauna",
        "Rēzekne",
        "Rēzekne (City)",
        "Riebiņi",
        "Rīga",
        "Roja",
        "Ropaži",
        "Rucava",
        "Rugāji",
        "Rundāle",
        "Rūjiena",
        "Sala",
        "Salacgrīva",
        "Salaspils",
        "Saldus",
        "Saulkrasti",
        "Sēja",
        "Sigulda",
        "Skrīveri",
        "Skrunda",
        "Smiltene",
        "Stopiņi",
        "Strenči",
        "Talsi",
        "Tērvete",
        "Tukums",
        "Vaiņode",
        "Valka",
        "Valmiera",
        "Varakļāni",
        "Vārkava",
        "Vecpiebalga",
        "Vecumnieki",
        "Ventspils",
        "Ventspils (City)",
        "Viesīte",
        "Viļaka",
        "Viļāni",
        "Zilupe"
      ]
    },
    {
      "optionId": "Lebanon",
      "code": "LB",
      "subOptions": [
        "Aakkâr",
        "Baalbelk-Hermel",
        "Béqaa",
        "Beyrouth",
        "Liban-Nord",
        "Liban-Sud",
        "Mont-Liban",
        "Nabatîyé"
      ]
    },
    {
      "optionId": "Lesotho",
      "code": "LS",
      "subOptions": [
        "Berea",
        "Butha-Buthe",
        "Leribe",
        "Mafeteng",
        "Maseru",
        "Mohales Hoek",
        "Mokhotlong",
        "Qacha's Nek",
        "Quthing",
        "Thaba-Tseka"
      ]
    },
    {
      "optionId": "Liberia",
      "code": "LR",
      "subOptions": [
        "Bomi",
        "Bong",
        "Gbarpolu",
        "Grand Bassa",
        "Grand Cape Mount",
        "Grand Gedeh",
        "Grand Kru",
        "Lofa",
        "Margibi",
        "Maryland",
        "Montserrado",
        "Nimba",
        "River Cess",
        "River Geee",
        "Sinoe"
      ]
    },
    {
      "optionId": "Libya",
      "code": "LY",
      "subOptions": [
        "Al Buţnān",
        "Al Jabal al Akhḑar",
        "Al Jabal al Gharbī",
        "Al Jafārah",
        "Al Jufrah",
        "Al Kufrah",
        "Al Marj",
        "Al Marquab",
        "Al Wāḩāt",
        "An Nuqaţ al Khams",
        "Az Zāwiyah",
        "Banghāzī",
        "Darnah",
        "Ghāt",
        "Mişrātah",
        "Murzuq",
        "Nālūt",
        "Sabhā",
        "Surt",
        "Ţarābulus",
        "Yafran",
        "Wādī ash Shāţiʾ"
      ]
    },
    {
      "optionId": "Liechtenstein",
      "code": "LI",
      "subOptions": [
        "Balzers",
        "Eschen",
        "Gamprin",
        "Mauren",
        "Planken",
        "Ruggell",
        "Schaan",
        "Schellenberg",
        "Triesen",
        "Triesenberg",
        "Vaduz"
      ]
    },
    {
      "optionId": "Lithuania",
      "code": "LT",
      "subOptions": [
        "Alytaus",
        "Kauno",
        "Klaipėdos",
        "Marijampolės",
        "Panevėžio",
        "Šiaulių",
        "Tauragės",
        "Telšių",
        "Utenos",
        "Vilniaus"
      ]
    },
    {
      "optionId": "Luxembourg",
      "code": "LU",
      "subOptions": [
        "Capellen",
        "Clevaux",
        "Diekirch",
        "Echternach",
        "Esch-sur-Alzette",
        "Grevenmacher",
        "Luxembourg",
        "Mersch",
        "Redange",
        "Remich",
        "Vianden",
        "Wiltz"
      ]
    },
    {
      "optionId": "Macao",
      "code": "MO",
      "subOptions": [
        "Macao"
      ]
    },
    {
      "optionId": "Macedonia, Republic of",
      "code": "MK",
      "subOptions": [
        "Aračinovo",
        "Berovo",
        "Bitola",
        "Bogdanci",
        "Bogovinje",
        "Bosilovo",
        "Brvenica",
        "Centar Župa",
        "Čaška",
        "Češinovo-Obleševo",
        "Čučer Sandevo",
        "Debar",
        "Debarca",
        "Delčevo",
        "Demir Hisar",
        "Demir Kapija",
        "Doran",
        "Dolneni",
        "Gevgelija",
        "Gostivar",
        "Gradsko",
        "Ilinden",
        "Jegunovce",
        "Karbinci",
        "Kavadarci",
        "Kičevo",
        "Kočani",
        "Konče",
        "Kratovo",
        "Kriva Palanka",
        "Krivogaštani",
        "Kruševo",
        "Kumanovo",
        "Lipkovo",
        "Lozovo",
        "Makedonska Kamenica",
        "Makedonski Brod",
        "Mavrovo i Rostuša",
        "Mogila",
        "Negotino",
        "Novaci",
        "Novo Selo",
        "Ohrid",
        "Pehčevo",
        "Petrovec",
        "Plasnica",
        "Prilep",
        "Probištip",
        "Radoviš",
        "Rankovce",
        "Resen",
        "Rosoman",
        "Skopje",
        "Sopište",
        "Staro Nagoričane",
        "Struga",
        "Strumica",
        "Studeničani",
        "Sveti Nikole",
        "Štip",
        "Tearce",
        "Tetovo",
        "Valandovo",
        "Vasilevo",
        "Veles",
        "Vevčani",
        "Vinica",
        "Vrapčište",
        "Zelenikovo",
        "Zrnovci",
        "Želino"
      ]
    },
    {
      "optionId": "Madagascar",
      "code": "MG",
      "subOptions": [
        "Antananarivo",
        "Antsiranana",
        "Fianarantsoa",
        "Mahajanga",
        "Toamasina",
        "Toliara"
      ]
    },
    {
      "optionId": "Malawi",
      "code": "MW",
      "subOptions": [
        "Balaka",
        "Blantyre",
        "Chikwawa",
        "Chiradzulu",
        "Chitipa",
        "Dedza",
        "Dowa",
        "Karonga",
        "Kasungu",
        "Likoma",
        "Lilongwe",
        "Machinga",
        "Mangochi",
        "Mchinji",
        "Mulanje",
        "Mwanza",
        "Mzimba",
        "Nkhata Bay",
        "Nkhotakota",
        "Nsanje",
        "Ntcheu",
        "Ntchisi",
        "Phalombe",
        "Rumphi",
        "Salima",
        "Thyolo",
        "Zomba"
      ]
    },
    {
      "optionId": "Malaysia",
      "code": "MY",
      "subOptions": [
        "Johor",
        "Kedah",
        "Kelantan",
        "Melaka",
        "Negeri Sembilan",
        "Pahang",
        "Perak",
        "Perlis",
        "Pulau Pinang",
        "Sabah",
        "Sarawak",
        "Selangor",
        "Terengganu",
        "Wilayah Persekutuan (Kuala Lumpur)",
        "Wilayah Persekutuan (Labuan)",
        "Wilayah Persekutuan (Putrajaya)"
      ]
    },
    {
      "optionId": "Maldives",
      "code": "MV",
      "subOptions": [
        "Alifu Alifu",
        "Alifu Dhaalu",
        "Baa",
        "Dhaalu",
        "Faafu",
        "Gaafu Alifu",
        "Gaafu Dhaalu",
        "Gnaviyani",
        "Haa Alifu",
        "Haa Dhaalu",
        "Kaafu",
        "Laamu",
        "Lhaviyani",
        "Malé",
        "Meemu",
        "Noonu",
        "Raa",
        "Seenu",
        "Shaviyani",
        "Thaa",
        "Vaavu"
      ]
    },
    {
      "optionId": "Mali",
      "code": "ML",
      "subOptions": [
        "Bamako",
        "Gao",
        "Kayes",
        "Kidal",
        "Koulikoro",
        "Mopti",
        "Segou",
        "Sikasso",
        "Tombouctou",
        "Taoudénit",
        "Ménaka"
      ]
    },
    {
      "optionId": "Malta",
      "code": "MT",
      "subOptions": [
        "Attard",
        "Balzan",
        "Birgu",
        "Birkirkara",
        "Birżebbuġa",
        "Bormla",
        "Dingli",
        "Fgura",
        "Floriana",
        "Fontana",
        "Guda",
        "Gżira",
        "Għajnsielem",
        "Għarb",
        "Għargħur",
        "Għasri",
        "Għaxaq",
        "Ħamrun",
        "Iklin",
        "Isla",
        "Kalkara",
        "Kerċem",
        "Kirkop",
        "Lija",
        "Luqa",
        "Marsa",
        "Marsaskala",
        "Marsaxlokk",
        "Mdina",
        "Mellieħa",
        "Mġarr",
        "Mosta",
        "Mqabba",
        "Msida",
        "Mtarfa",
        "Munxar",
        "Nadur",
        "Naxxar",
        "Paola",
        "Pembroke",
        "Pietà",
        "Qala",
        "Qormi",
        "Qrendi",
        "Rabat Għawdex",
        "Rabat Malta",
        "Safi",
        "San Ġiljan",
        "San Ġwann",
        "San Lawrenz",
        "San Pawl il-Baħar",
        "Sannat",
        "Santa Luċija",
        "Santa Venera",
        "Siġġiewi",
        "Sliema",
        "Swieqi",
        "Tai Xbiex",
        "Tarzien",
        "Valletta",
        "Xagħra",
        "Xewkija",
        "Xgħajra",
        "Żabbar",
        "Żebbuġ Għawde",
        "Żebbuġ Malta",
        "Żejtun",
        "Żurrieq"
      ]
    },
    {
      "optionId": "Marshall Islands",
      "code": "MH",
      "subOptions": [
        "Ailinglaplap",
        "Ailuk",
        "Arno",
        "Aur",
        "Bikini and Kili",
        "Ebon",
        "Jabat",
        "Jaluit",
        "Kwajalein",
        "Lae",
        "Lib",
        "Likiep",
        "Majuro",
        "Maloelap",
        "Mejit",
        "Namdrik",
        "Namu",
        "Rongelap",
        "Ujae",
        "Utrik",
        "Wotho",
        "Wotje"
      ]
    },
    {
      "optionId": "Martinique",
      "code": "MQ",
      "subOptions": [
        "Martinique"
      ]
    },
    {
      "optionId": "Mauritania",
      "code": "MR",
      "subOptions": [
        "Adrar",
        "Assaba",
        "Brakna",
        "Dakhlet Nouadhibou",
        "Gorgol",
        "Guidimaka",
        "Hodh Ech Chargui",
        "Hodh El Gharbi",
        "Inchiri",
        "Nouakchott Nord",
        "Nouakchott Ouest",
        "Nouakchott Sud",
        "Tagant",
        "Tiris Zemmour",
        "Trarza"
      ]
    },
    {
      "optionId": "Mauritius",
      "code": "MU",
      "subOptions": [
        "Agalega Islands",
        "Beau Bassin-Rose Hill",
        "Black River",
        "Cargados Carajos Shoals",
        "Curepipe",
        "Flacq",
        "Grand Port",
        "Moka",
        "Pamplemousses",
        "Plaines Wilhems",
        "Port Louis (City)",
        "Port Louis",
        "Riviere du Rempart",
        "Rodrigues Island",
        "Savanne",
        "Vacoas-Phoenix"
      ]
    },
    {
      "optionId": "Mayotte",
      "code": "YT",
      "subOptions": [
        "Dzaoudzi",
        "Pamandzi",
        "Mamoudzou",
        "Dembeni",
        "Bandrélé",
        "Kani-Kéli",
        "Bouéni",
        "Chirongui",
        "Sada",
        "Ouangani",
        "Chiconi",
        "Tsingoni",
        "M'Tsangamouji",
        "Acoua",
        "Mtsamboro",
        "Bandraboua",
        "Koungou"
      ]
    },
    {
      "optionId": "Mexico",
      "code": "MX",
      "subOptions": [
        "Aguascalientes",
        "Baja California",
        "Baja California Sur",
        "Campeche",
        "Ciudad de México",
        "Chiapas",
        "Chihuahua",
        "Coahuila de Zaragoza",
        "Colima",
        "Durango",
        "Estado de México",
        "Guanajuato",
        "Guerrero",
        "Hidalgo",
        "Jalisco",
        "Michoacán de Ocampo",
        "Morelos",
        "Nayarit",
        "Nuevo León",
        "Oaxaca",
        "Puebla",
        "Querétaro de Arteaga",
        "Quintana Roo",
        "San Luis Potosí",
        "Sinaloa",
        "Sonora",
        "Tabasco",
        "Tamaulipas",
        "Tlaxcala",
        "Veracruz",
        "Yucatán",
        "Zacatecas"
      ]
    },
    {
      "optionId": "Micronesia, Federated States of",
      "code": "FM",
      "subOptions": [
        "Chuuk (Truk)",
        "Kosrae",
        "Pohnpei",
        "Yap"
      ]
    },
    {
      "optionId": "Moldova",
      "code": "MD",
      "subOptions": [
        "Aenii Noi",
        "Basarabeasca",
        "Bălți",
        "Bender",
        "Briceni",
        "Cahul",
        "Cantemir",
        "Călărași",
        "Căușeni",
        "Chișinău",
        "Cimișlia",
        "Criuleni",
        "Dondușeni",
        "Drochia",
        "Dubăsari",
        "Edineț",
        "Fălești",
        "Florești",
        "Găgăuzia",
        "Glodeni",
        "Hîncești",
        "Ialoveni",
        "Leova",
        "Nisporeni",
        "Ocnița",
        "Orhei",
        "Rezina",
        "Rîșcani",
        "Sîngerei",
        "Soroca",
        "Stânga Nistrului",
        "Strășeni",
        "Șoldănești",
        "Ștefan Vodă",
        "Taraclia",
        "Telenești",
        "Ungheni"
      ]
    },
    {
      "optionId": "Monaco",
      "code": "MC",
      "subOptions": [
        "Colle",
        "Condamine",
        "Fontvieille",
        "Gare",
        "Jardin Exotique",
        "Larvotto",
        "Malbousquet",
        "Monaco-Ville",
        "Moneghetti",
        "Monte-Carlo",
        "Moulins",
        "Port-Hercule",
        "Saint-Roman",
        "Sainte-Dévote",
        "Source",
        "Spélugues",
        "Vallon de la Rousse"
      ]
    },
    {
      "optionId": "Mongolia",
      "code": "MN",
      "subOptions": [
        "Arhangay",
        "Bayan-Olgiy",
        "Bayanhongor",
        "Bulgan",
        "Darhan",
        "Dornod",
        "Dornogovi",
        "Dundgovi",
        "Dzavhan",
        "Govi-Altay",
        "Govi-Sumber",
        "Hovd",
        "Hovsgol",
        "Omnogovi",
        "Ovorhangay",
        "Selenge",
        "Suhbaatar",
        "Tov",
        "Ulaanbaatar",
        "Uvs",
        "Erdenet"
      ]
    },
    {
      "optionId": "Montenegro",
      "code": "ME",
      "subOptions": [
        "Andrijevica",
        "Bar",
        "Berane",
        "Bijelo Polje",
        "Budva",
        "Cetinje",
        "Danilovgrad",
        "Gusinje",
        "Herceg Novi",
        "Kolašin",
        "Kotor",
        "Mojkovac",
        "Nikšić",
        "Petnica",
        "Plav",
        "Plužine",
        "Pljevlja",
        "Podgorica",
        "Rožaje",
        "Šavnik",
        "Tivat",
        "Ulcinj",
        "Žabljak"
      ]
    },
    {
      "optionId": "Montserrat",
      "code": "MS",
      "subOptions": [
        "Saint Anthony",
        "Saint Georges",
        "Saint Peter's"
      ]
    },
    {
      "optionId": "Morocco",
      "code": "MA",
      "subOptions": [
        "Tanger-Tétouan-Al Hoceïma",
        "L'Oriental",
        "Fès-Meknès",
        "Rabat-Salé-Kénitra",
        "Béni Mellal-Khénifra",
        "Casablanca-Settat",
        "Marrakech-Safi",
        "Drâa-Tafilalet",
        "Souss-Massa",
        "Guelmim-Oued Noun",
        "Laâyoune-Sakia El Hamra",
        "Dakhla-Oued Ed-Dahab"
      ]
    },
    {
      "optionId": "Mozambique",
      "code": "MZ",
      "subOptions": [
        "Cabo Delgado",
        "Gaza",
        "Inhambane",
        "Manica",
        "Maputo",
        "Maputo (City)",
        "Nampula",
        "Niassa",
        "Sofala",
        "Tete",
        "Zambezia"
      ]
    },
    {
      "optionId": "Myanmar",
      "code": "MM",
      "subOptions": [
        "Ayeyarwady",
        "Bago",
        "Chin",
        "Kachin",
        "Kayah",
        "Kayin",
        "Magway",
        "Mandalay",
        "Mon",
        "Nay Pyi Taw",
        "Rakhine",
        "Sagaing",
        "Shan",
        "Tanintharyi",
        "Yangon"
      ]
    },
    {
      "optionId": "Namibia",
      "code": "NA",
      "subOptions": [
        "Erongo",
        "Hardap",
        "Kavango East",
        "Kavango West",
        "Karas",
        "Khomas",
        "Kunene",
        "Ohangwena",
        "Omaheke",
        "Omusati",
        "Oshana",
        "Oshikoto",
        "Otjozondjupa",
        "Zambezi"
      ]
    },
    {
      "optionId": "Nauru",
      "code": "NR",
      "subOptions": [
        "Aiwo",
        "Anabar",
        "Anetan",
        "Anibare",
        "Baiti",
        "Boe",
        "Buada",
        "Denigomodu",
        "Ewa",
        "Ijuw",
        "Meneng",
        "Nibok",
        "Uaboe",
        "Yaren"
      ]
    },
    {
      "optionId": "Nepal",
      "code": "NP",
      "subOptions": [
        "Province No. 1",
        "Madhesh Province",
        "Bagmati Province",
        "Gandaki Province",
        "Lumbini Province",
        "Karnali Province",
        "Sudurpashchim Province"
      ]
    },
    {
      "optionId": "Netherlands",
      "code": "NL",
      "subOptions": [
        "Drenthe",
        "Flevoland",
        "Friesland",
        "Gelderland",
        "Groningen",
        "Limburg",
        "North Brabant",
        "North Holland",
        "Overijssel",
        "South Holland",
        "Utrecht",
        "Zeeland"
      ]
    },
    {
      "optionId": "New Caledonia",
      "code": "NC",
      "subOptions": [
        "Iles Loyaute",
        "Nord",
        "Sud"
      ]
    },
    {
      "optionId": "New Zealand",
      "code": "NZ",
      "subOptions": [
        "Auckland",
        "Bay of Plenty",
        "Canterbury",
        "Gisborne",
        "Hawke's Bay",
        "Marlborough",
        "Manawatu-Wanganui",
        "Northland",
        "Nelson",
        "Otago",
        "Southland",
        "Taranaki",
        "Tasman",
        "Waikato",
        "Wellington",
        "West Coast",
        "Chatham Islands Territory"
      ]
    },
    {
      "optionId": "Nicaragua",
      "code": "NI",
      "subOptions": [
        "Boaco",
        "Carazo",
        "Chinandega",
        "Chontales",
        "Estelí",
        "Granada",
        "Jinotega",
        "León",
        "Madriz",
        "Managua",
        "Masaya",
        "Matagalpa",
        "Nueva Segovia",
        "Río San Juan",
        "Rivas",
        "Atlántico Norte",
        "Atlántico Sur"
      ]
    },
    {
      "optionId": "Niger",
      "code": "NE",
      "subOptions": [
        "Agadez",
        "Diffa",
        "Dosso",
        "Maradi",
        "Niamey",
        "Tahoua",
        "Tillabéri",
        "Zinder"
      ]
    },
    {
      "optionId": "Nigeria",
      "code": "NG",
      "subOptions": [
        "Abia",
        "Abuja Federal Capital Territory",
        "Adamawa",
        "Akwa Ibom",
        "Anambra",
        "Bauchi",
        "Bayelsa",
        "Benue",
        "Borno",
        "Cross River",
        "Delta",
        "Ebonyi",
        "Edo",
        "Ekiti",
        "Enugu",
        "Gombe",
        "Imo",
        "Jigawa",
        "Kaduna",
        "Kano",
        "Katsina",
        "Kebbi",
        "Kogi",
        "Kwara",
        "Lagos",
        "Nassarawa",
        "Niger",
        "Ogun",
        "Ondo",
        "Osun",
        "Oyo",
        "Plateau",
        "Rivers",
        "Sokoto",
        "Taraba",
        "Yobe",
        "Zamfara"
      ]
    },
    {
      "optionId": "Niue",
      "code": "NU",
      "subOptions": [
        "Niue"
      ]
    },
    {
      "optionId": "Norfolk Island",
      "code": "NF",
      "subOptions": [
        "Norfolk Island"
      ]
    },
    {
      "optionId": "Northern Ireland",
      "code": "gb-nir",
      "subOptions": [
        "Antrim",
        "Armagh",
        "Down",
        "Fermanagh",
        "Londonderry",
        "Tyrone"
      ]
    },
    {
      "optionId": "Northern Mariana Islands",
      "code": "MP",
      "subOptions": [
        "Northern Islands",
        "Rota",
        "Saipan",
        "Tinian"
      ]
    },
    {
      "optionId": "Norway",
      "code": "NO",
      "subOptions": [
        "Agder",
        "Innlandet",
        "Møre og Romsdal",
        "Nordland",
        "Oslo",
        "Rogaland",
        "Svalbard",
        "Troms og Finnmark",
        "Trøndelag",
        "Vestfold og Telemark",
        "Vestland",
        "Viken"
      ]
    },
    {
      "optionId": "Oman",
      "code": "OM",
      "subOptions": [
        "Ad Dakhiliyah",
        "Al Buraymi",
        "Al Wusta",
        "Az Zahirah",
        "Janub al Batinah",
        "Janub ash Sharqiyah",
        "Masqat",
        "Musandam",
        "Shamal al Batinah",
        "Shamal ash Sharqiyah",
        "Zufar"
      ]
    },
    {
      "optionId": "Pakistan",
      "code": "PK",
      "subOptions": [
        "Āzād Kashmīr",
        "Balōchistān",
        "Gilgit-Baltistān",
        "Islāmābād",
        "Khaībar Pakhtūnkhwās",
        "Punjāb",
        "Sindh",
        "Federally Administered Tribal Areas"
      ]
    },
    {
      "optionId": "Palau",
      "code": "PW",
      "subOptions": [
        "Aimeliik",
        "Airai",
        "Angaur",
        "Hatobohei",
        "Kayangel",
        "Koror",
        "Melekeok",
        "Ngaraard",
        "Ngarchelong",
        "Ngardmau",
        "Ngatpang",
        "Ngchesar",
        "Ngeremlengui",
        "Ngiwal",
        "Peleliu",
        "Sonsoral"
      ]
    },
    {
      "optionId": "Palestine, State of",
      "code": "PS",
      "subOptions": [
        "Ak Khalīl",
        "Al Quds",
        "Arīḩā wal Aghwār",
        "Bayt Laḩm",
        "Dayr al Balaḩ",
        "Ghazzah",
        "Janīn",
        "Khān Yūnis",
        "Nāblus",
        "Qalqīyah",
        "Rafaḩ",
        "Rām Allāh wal Bīrah",
        "Salfīt",
        "Shamāl Ghazzah",
        "Ţūbās",
        "Ţūlkarm"
      ]
    },
    {
      "optionId": "Panama",
      "code": "PA",
      "subOptions": [
        "Bocas del Toro",
        "Chiriquí",
        "Coclé",
        "Colón",
        "Darién",
        "Emberá",
        "Herrera",
        "Kuna Yala",
        "Los Santos",
        "Ngäbe-Buglé",
        "Panamá",
        "Panamá Oeste",
        "Veraguas"
      ]
    },
    {
      "optionId": "Papua New Guinea",
      "code": "PG",
      "subOptions": [
        "Bougainville",
        "Central",
        "Chimbu",
        "East New Britain",
        "East Sepik",
        "Eastern Highlands",
        "Enga",
        "Gulf",
        "Hela",
        "Jiwaka",
        "Madang",
        "Manus",
        "Milne Bay",
        "Morobe",
        "Port Moresby",
        "New Ireland",
        "Northern",
        "Southern Highlands",
        "West New Britain",
        "West Sepik",
        "Western",
        "Western Highlands"
      ]
    },
    {
      "optionId": "Paraguay",
      "code": "PY",
      "subOptions": [
        "Alto Paraguay",
        "Alto Parana",
        "Amambay",
        "Asuncion",
        "Caaguazu",
        "Caazapa",
        "Canindeyu",
        "Central",
        "Concepcion",
        "Cordillera",
        "Boqueron",
        "Guaira",
        "Itapua",
        "Misiones",
        "Neembucu",
        "Paraguari",
        "Presidente Hayes",
        "San Pedro"
      ]
    },
    {
      "optionId": "Peru",
      "code": "PE",
      "subOptions": [
        "Amazonas",
        "Ancash",
        "Apurimac",
        "Arequipa",
        "Ayacucho",
        "Cajamarca",
        "Callao",
        "Cusco",
        "Huancavelica",
        "Huanuco",
        "Ica",
        "Junin",
        "La Libertad",
        "Lambayeque",
        "Lima",
        "Loreto",
        "Madre de Dios",
        "Moquegua",
        "Municipalidad Metropolitana de Lima",
        "Pasco",
        "Piura",
        "Puno",
        "San Martin",
        "Tacna",
        "Tumbes",
        "Ucayali"
      ]
    },
    {
      "optionId": "Philippines",
      "code": "PH",
      "subOptions": [
        "Abra",
        "Agusan del Norte",
        "Agusan del Sur",
        "Aklan",
        "Albay",
        "Antique",
        "Apayao",
        "Aurora",
        "Basilan",
        "Bataan",
        "Batanes",
        "Batangas",
        "Benguet",
        "Biliran",
        "Bohol",
        "Bukidnon",
        "Bulacan",
        "Cagayan",
        "Camarines Norte",
        "Camarines Sur",
        "Camiguin",
        "Capiz",
        "Catanduanes",
        "Cavite",
        "Cebu",
        "Cotabato",
        "Davao del Norte",
        "Davao del Sur",
        "Davao Occidental",
        "Davao Oriental",
        "Davao de Oro",
        "Dinagat Islands",
        "Eastern Samar",
        "Guimaras",
        "Ifugao",
        "Ilocos Norte",
        "Ilocos Sur",
        "Iloilo",
        "Isabela",
        "Kalinga",
        "La Union",
        "Laguna",
        "Lanao del Norte",
        "Lanao del Sur",
        "Leyte",
        "Maguindanao",
        "Marinduque",
        "Masbate",
        "Metro Manila",
        "Mindoro Occidental",
        "Mindoro Oriental",
        "Misamis Occidental",
        "Misamis Oriental",
        "Mountain Province",
        "Negros Occidental",
        "Negros Oriental",
        "Northern Samar",
        "Nueva Ecija",
        "Nueva Vizcaya",
        "Palawan",
        "Pampanga",
        "Pangasinan",
        "Quezon",
        "Quirino",
        "Rizal",
        "Romblon",
        "Samar",
        "Sarangani",
        "Siquijor",
        "Sorsogon",
        "South Cotabato",
        "Southern Leyte",
        "Sultan Kudarat",
        "Sulu",
        "Surigao del Norte",
        "Surigao del Sur",
        "Tarlac",
        "Tawi-Tawi",
        "Zambales",
        "Zamboanga del Norte",
        "Zamboanga del Sur",
        "Zamboanga Sibugay"
      ]
    },
    {
      "optionId": "Pitcairn",
      "code": "PN",
      "subOptions": [
        "Pitcairn Islands"
      ]
    },
    {
      "optionId": "Poland",
      "code": "PL",
      "subOptions": [
        "Dolnośląskie",
        "Kujawsko-pomorskie",
        "Lubelskie",
        "Lubuskie",
        "Łódzkie",
        "Małopolskie",
        "Mazowieckie",
        "Opolskie",
        "Podkarpackie",
        "Podlaskie",
        "Pomorskie",
        "Śląskie",
        "Świętokrzyskie",
        "Warmińsko-mazurskie",
        "Wielkopolskie",
        "Zachodniopomorskie"
      ]
    },
    {
      "optionId": "Portugal",
      "code": "PT",
      "subOptions": [
        "Aveiro",
        "Beja",
        "Braga",
        "Bragança",
        "Castelo Branco",
        "Coimbra",
        "Faro",
        "Guarda",
        "Leiria",
        "Lisboa",
        "Portalegre",
        "Porto",
        "Região Autónoma da Madeira",
        "Região Autónoma dos Açores",
        "Santarém",
        "Setúbal",
        "Viana do Castelo",
        "Vila Real",
        "Viseu",
        "Évora"
      ]
    },
    {
      "optionId": "Puerto Rico",
      "code": "PR",
      "subOptions": [
        "Adjuntas",
        "Aguada",
        "Aguadilla",
        "Aguas Buenas",
        "Aibonito",
        "Anasco",
        "Arecibo",
        "Arroyo",
        "Barceloneta",
        "Barranquitas",
        "Bayamon",
        "Cabo Rojo",
        "Caguas",
        "Camuy",
        "Canovanas",
        "Carolina",
        "Cat",
        "Ceiba",
        "Ciales",
        "Cidra",
        "Coamo",
        "Comerio",
        "Corozal",
        "Culebra",
        "Dorado",
        "Fajardo",
        "Florida",
        "Guanica",
        "Guayama",
        "Guayanilla",
        "Guaynabo",
        "Gurabo",
        "Hatillo",
        "Hormigueros",
        "Humacao",
        "Isabe",
        "Juana Diaz",
        "Juncos",
        "Lajas",
        "Lares",
        "Las Marias",
        "Las oiza",
        "Luquillo",
        "Manati",
        "Maricao",
        "Maunabo",
        "Mayaguez",
        "Moca",
        "Morovis",
        "Naguabo",
        "Naranjito",
        "Orocovis",
        "Patillas",
        "Penuelas",
        "Ponce",
        "Quebradillas",
        "Rincon",
        "Rio Grande",
        "Sabana linas",
        "San German",
        "San Juan",
        "San Lorenzo",
        "San Sebastian",
        "Santa Isabel",
        "Toa Alta",
        "Toa Baja",
        "Trujillo Alto",
        "Utuado",
        "Vega Alta",
        "Vega ues",
        "Villalba",
        "Yabucoa",
        "Yauco"
      ]
    },
    {
      "optionId": "Qatar",
      "code": "QA",
      "subOptions": [
        "Ad Dawḩah",
        "Al Khawr wa adh Dhakhīrah",
        "Al Wakrah",
        "Ar Rayyān",
        "Ash Shamāl",
        "Az̧ Za̧`āyin",
        "Umm Şalāl"
      ]
    },
    {
      "optionId": "Réunion",
      "code": "RE",
      "subOptions": [
        "Réunion"
      ]
    },
    {
      "optionId": "Romania",
      "code": "RO",
      "subOptions": [
        "Nord-Est",
        "Sud-Est",
        "Sud-Muntenia",
        "Sud-Vest Oltenia",
        "Vest",
        "Nord-Vest",
        "Centru",
        "București - Ilfov"
      ]
    },
    {
      "optionId": "Russian Federation",
      "code": "RU",
      "subOptions": [
        "Republic of Adygea",
        "Republic of Altai (Gorno-Altaysk)",
        "Altai Krai",
        "Amur Oblast",
        "Arkhangelsk Oblast",
        "Astrakhan Oblast",
        "Republic of Bashkortostan",
        "Belgorod Oblast",
        "Bryansk Oblast",
        "Republic of Buryatia",
        "Chechen Republic",
        "Chelyabinsk Oblast",
        "Chukotka Autonomous Okrug",
        "Chuvash Republic",
        "Republic of Dagestan",
        "Republic of Ingushetia",
        "Irkutsk Oblast",
        "Ivanovo Oblast",
        "Jewish Autonomous Oblast",
        "Kabardino-Balkar Republic",
        "Kaliningrad Oblast",
        "Republic of Kalmykia",
        "Kaluga Oblast",
        "Kamchatka Krai",
        "Karachay-Cherkess Republic",
        "Republic of Karelia",
        "Khabarovsk Krai",
        "Republic of Khakassia",
        "Khanty-Mansi Autonomous Okrug - Yugra",
        "Kemerovo Oblast",
        "Kirov Oblast",
        "Komi Republic",
        "Kostroma Oblast",
        "Krasnodar Krai",
        "Krasnoyarsk Krai",
        "Kurgan Oblast",
        "Kursk Oblast",
        "Leningrad Oblast",
        "Lipetsk Oblast",
        "Magadan Oblast",
        "Mari El Republic",
        "Republic of Mordovia",
        "Moscow Oblast",
        "Moscow",
        "Murmansk Oblast",
        "Nenets Autonomous Okrug",
        "Nizhny Novgorod Oblast",
        "Novgorod Oblast",
        "Novosibirsk Oblast",
        "Omsk Oblast",
        "Orenburg Oblast",
        "Oryol Oblast",
        "Penza Oblast",
        "Perm Krai",
        "Primorsky Krai",
        "Pskov Oblast",
        "Rostov Oblast",
        "Ryazan Oblast",
        "Saint Petersburg",
        "Sakha (Yakutia) Republic",
        "Sakhalin Oblast",
        "Samara Oblast",
        "Saratov Oblast",
        "Republic of North Ossetia-Alania",
        "Smolensk Oblast",
        "Stavropol Krai",
        "Sverdlovsk Oblast",
        "Tambov Oblast",
        "Republic of Tatarstan",
        "Tomsk Oblast",
        "Tuva Republic",
        "Tula Oblast",
        "Tver Oblast",
        "Tyumen Oblast",
        "Udmurt Republic",
        "Ulyanovsk Oblast",
        "Vladimir Oblast",
        "Volgograd Oblast",
        "Vologda Oblast",
        "Voronezh Oblast",
        "Yamalo-Nenets Autonomous Okrug",
        "Yaroslavl Oblast",
        "Zabaykalsky Krai"
      ]
    },
    {
      "optionId": "Rwanda",
      "code": "RW",
      "subOptions": [
        "Kigali",
        "Eastern",
        "Northern",
        "Western",
        "Southern"
      ]
    },
    {
      "optionId": "Saint Barthélemy",
      "code": "BL",
      "subOptions": [
        "Au Vent",
        "Sous le Vent"
      ]
    },
    {
      "optionId": "Saint Helena, Ascension and Tristan da Cunha",
      "code": "SH",
      "subOptions": [
        "Ascension",
        "Saint Helena",
        "Tristan da Cunha"
      ]
    },
    {
      "optionId": "Saint Kitts and Nevis",
      "code": "KN",
      "subOptions": [
        "Saint Kitts",
        "Nevis"
      ]
    },
    {
      "optionId": "Saint Lucia",
      "code": "LC",
      "subOptions": [
        "Anse-la-Raye",
        "Canaries",
        "Castries",
        "Choiseul",
        "Dennery",
        "Gros Islet",
        "Laborie",
        "Micoud",
        "Soufriere",
        "Vieux Fort"
      ]
    },
    {
      "optionId": "Saint Martin",
      "code": "MF",
      "subOptions": [
        "Saint Martin"
      ]
    },
    {
      "optionId": "Saint Pierre and Miquelon",
      "code": "PM",
      "subOptions": [
        "Miquelon",
        "Saint Pierre"
      ]
    },
    {
      "optionId": "Saint Vincent and the Grenadines",
      "code": "VC",
      "subOptions": [
        "Charlotte",
        "Grenadines",
        "Saint Andrew",
        "Saint David",
        "Saint George",
        "Saint Patrick"
      ]
    },
    {
      "optionId": "Samoa",
      "code": "WS",
      "subOptions": [
        "A'ana",
        "Aiga-i-le-Tai",
        "Atua",
        "Fa'asaleleaga",
        "Gaga'emauga",
        "Gagaifomauga",
        "Palauli",
        "Satupa'itea",
        "Tuamasaga",
        "Va'a-o-Fonoti",
        "Vaisigano"
      ]
    },
    {
      "optionId": "San Marino",
      "code": "SM",
      "subOptions": [
        "Acquaviva",
        "Borgo Maggiore",
        "Chiesanuova",
        "Domagnano",
        "Faetano",
        "Fiorentino",
        "Montegiardino",
        "San Marino",
        "Serravalle"
      ]
    },
    {
      "optionId": "Sao Tome and Principe",
      "code": "ST",
      "subOptions": [
        "Principe",
        "Sao Tome"
      ]
    },
    {
      "optionId": "Saudi Arabia",
      "code": "SA",
      "subOptions": [
        "'Asir",
        "Al Bahah",
        "Al Hudud ash Shamaliyah",
        "Al Jawf",
        "Al Madinah al Munawwarah",
        "Al Qasim",
        "Ar Riyad",
        "Ash Sharqiyah",
        "Ha'il",
        "Jazan",
        "Makkah al Mukarramah",
        "Najran",
        "Tabuk"
      ]
    },
    {
      "optionId": "Senegal",
      "code": "SN",
      "subOptions": [
        "Dakar",
        "Diourbel",
        "Fatick",
        "Kaffrine",
        "Kaolack",
        "Kedougou",
        "Kolda",
        "Louga",
        "Matam",
        "Saint-Louis",
        "Sedhiou",
        "Tambacounda",
        "Thies",
        "Ziguinchor"
      ]
    },
    {
      "optionId": "Serbia",
      "code": "RS",
      "subOptions": [
        "Beograd (Belgrade)",
        "Borski",
        "Braničevski",
        "Jablanički",
        "Južnobački",
        "Južnobanatski",
        "Kolubarski",
        "Kosovski",
        "Kosovsko-Mitrovački",
        "Kosovsko-Pomoravski",
        "Mačvanski",
        "Moravički",
        "Nišavski",
        "Pčinjski",
        "Pećki",
        "Pirotski",
        "Podunavski",
        "Pomoravski",
        "Prizrenski",
        "Rasinski",
        "Raški",
        "Severnobački",
        "Severnobanatski",
        "Srednjebanatski",
        "Sremski",
        "Šumadijski",
        "Toplički",
        "Zaječarski",
        "Zapadnobački",
        "Zlatiborski"
      ]
    },
    {
      "optionId": "Seychelles",
      "code": "SC",
      "subOptions": [
        "Anse aux Pins",
        "Anse Boileau",
        "Anse Etoile",
        "Anse Royale",
        "Anu Cap",
        "Baie Lazare",
        "Baie Sainte Anne",
        "Beau Vallon",
        "Bel Air",
        "Bel Ombre",
        "Cascade",
        "Glacis",
        "Grand'Anse Mahe",
        "Grand'Anse Praslin",
        "La Digue",
        "La Riviere Anglaise",
        "Les Mamelles",
        "Mont Buxton",
        "Mont Fleuri",
        "Plaisance",
        "Pointe La Rue",
        "Port Glaud",
        "Roche Caiman",
        "Saint Louis",
        "Takamaka"
      ]
    },
    {
      "optionId": "Sierra Leone",
      "code": "SL",
      "subOptions": [
        "Eastern",
        "Northern",
        "Southern",
        "Western"
      ]
    },
    {
      "optionId": "Singapore",
      "code": "SG",
      "subOptions": [
        "Central Singapore",
        "North East",
        "North West",
        "South East",
        "South West"
      ]
    },
    {
      "optionId": "Sint Maarten (Dutch part)",
      "code": "SX",
      "subOptions": [
        "Sint Maarten"
      ]
    },
    {
      "optionId": "Slovakia",
      "code": "SK",
      "subOptions": [
        "Bratislava",
        "Trnava",
        "Trenčín",
        "Nitra",
        "Žilina",
        "Banská Bystrica",
        "Prešov",
        "Košice"
      ]
    },
    {
      "optionId": "Slovenia",
      "code": "SI",
      "subOptions": [
        "Pomurska",
        "Podravska",
        "Koroška",
        "Savinjska",
        "Zasavska",
        "Posavska",
        "Jugovzhodna Slovenija",
        "Primorsko-notranjska",
        "Osrednjeslovenska",
        "Gorenjska",
        "Goriška",
        "Obalno-kraška"
      ]
    },
    {
      "optionId": "Solomon Islands",
      "code": "SB",
      "subOptions": [
        "Central",
        "Choiseul",
        "Guadalcanal",
        "Honiara",
        "Isabel",
        "Makira-Ulawa",
        "Malaita",
        "Rennell and Bellona",
        "Temotu",
        "Western"
      ]
    },
    {
      "optionId": "Somalia",
      "code": "SO",
      "subOptions": [
        "Awdal",
        "Bakool",
        "Banaadir",
        "Bari",
        "Bay",
        "Galguduud",
        "Gedo",
        "Hiiraan",
        "Jubbada Dhexe",
        "Jubbada Hoose",
        "Mudug",
        "Nugaal",
        "Sanaag",
        "Shabeellaha Dhexe",
        "Shabeellaha Hoose",
        "Sool",
        "Togdheer",
        "Woqooyi Galbeed"
      ]
    },
    {
      "optionId": "South Africa",
      "code": "ZA",
      "subOptions": [
        "Eastern Cape",
        "Free State",
        "Gauteng",
        "KwaZulu-Natal",
        "Limpopo",
        "Mpumalanga",
        "Northern Cape",
        "North West",
        "Western Cape"
      ]
    },
    {
      "optionId": "South Georgia and South Sandwich Islands",
      "code": "GS",
      "subOptions": [
        "Bird Island",
        "Bristol Island",
        "Clerke Rocks",
        "Montagu Island",
        "Saunders Island",
        "South Georgia",
        "Southern Thule",
        "Traversay Islands"
      ]
    },
    {
      "optionId": "South Sudan",
      "code": "SS",
      "subOptions": [
        "Central Equatoria",
        "Eastern Equatoria",
        "Jonglei",
        "Lakes",
        "Northern Bahr el Ghazal",
        "Unity",
        "Upper Nile",
        "Warrap",
        "Western Bahr el Ghazal",
        "Western Equatoria"
      ]
    },
    {
      "optionId": "Spain",
      "code": "ES",
      "subOptions": [
        "Albacete",
        "Alicante\/Alacant",
        "Almer\u00eda",
        "Araba\/\u00c1lava",
        "Asturias",
        "Badajoz",
        "Balears, Illes",
        "Barcelona",
        "Bizkaia",
        "Burgos",
        "Cantabria",
        "Castell\u00f3n\/Castell\u00f3",
        "Ceuta",
        "Ciudad Real",
        "Coru\u00f1a, A",
        "Cuenca",
        "C\u00e1ceres",
        "C\u00e1diz",
        "C\u00f3rdoba",
        "Gipuzkoa",
        "Girona",
        "Granada",
        "Guadalajara",
        "Huelva",
        "Huesca",
        "Ja\u00e9n",
        "Le\u00f3n",
        "Lleida",
        "Lugo",
        "Madrid",
        "Melilla",
        "Murcia",
        "M\u00e1laga",
        "Navarra",
        "Ourense",
        "Palencia",
        "Palmas, Las",
        "Pontevedra",
        "Rioja, La",
        "Salamanca",
        "Santa Cruz de Tenerife",
        "Segovia",
        "Sevilla",
        "Soria",
        "Tarragona",
        "Teruel",
        "Toledo",
        "Valencia\/Val\u00e8ncia",
        "Valladolid",
        "Zamora",
        "Zaragoza",
        "\u00c1vila"
      ]
    },
    {
      "optionId": "Sri Lanka",
      "code": "LK",
      "subOptions": [
        "Basnahira",
        "Dakunu",
        "Madhyama",
        "Naegenahira",
        "Sabaragamuwa",
        "Uturu",
        "Uturumaeda",
        "Vayamba",
        "Uva"
      ]
    },
    {
      "optionId": "Sudan",
      "code": "SD",
      "subOptions": [
        "Al Bahr al Ahmar",
        "Al Jazirah",
        "Al Khartum",
        "Al Qadarif",
        "An Nil al Abyad",
        "An Nil al Azraq",
        "Ash Shamaliyah",
        "Gharb Darfur",
        "Gharb Kurdufan",
        "Janub Darfur",
        "Janub Kurdufan",
        "Kassala",
        "Nahr an Nil",
        "Shamal Darfur",
        "Sharq Darfur",
        "Shiamal Kurdufan",
        "Sinnar",
        "Wasat Darfur Zalinjay"
      ]
    },
    {
      "optionId": "Suriname",
      "code": "SR",
      "subOptions": [
        "Brokopondo",
        "Commewijne",
        "Coronie",
        "Marowijne",
        "Nickerie",
        "Para",
        "Paramaribo",
        "Saramacca",
        "Sipaliwini",
        "Wanica"
      ]
    },
    {
      "optionId": "Eswatini",
      "code": "SZ",
      "subOptions": [
        "Hhohho",
        "Lubombo",
        "Manzini",
        "Shiselweni"
      ]
    },
    {
      "optionId": "Sweden",
      "code": "SE",
      "subOptions": [
        "Blekinge",
        "Dalarna",
        "Gotland",
        "Gävleborg",
        "Halland",
        "Jämtland",
        "Jönköping",
        "Kalmar",
        "Kronoberg",
        "Norrbotten",
        "Skåne",
        "Stockholm",
        "Södermanland",
        "Uppsala",
        "Värmland",
        "Västerbotten",
        "Västernorrland",
        "Västermanland",
        "Västra Götaland",
        "Örebro",
        "Östergötland"
      ]
    },
    {
      "optionId": "Switzerland",
      "code": "CH",
      "subOptions": [
        "Eastern Switzerland",
        "Zürich",
        "Central Switzerland",
        "Northwestern Switzerland",
        "Espace Mittelland",
        "Région lémanique",
        "Ticino"
      ]
    },
    {
      "optionId": "Syrian Arab Republic",
      "code": "SY",
      "subOptions": [
        "Al Hasakah",
        "Al Ladhiqiyah",
        "Al Qunaytirah",
        "Ar Raqqah",
        "As Suwayda'",
        "Dar'a",
        "Dayr az Zawr",
        "Dimashq",
        "Halab",
        "Hamah",
        "Hims",
        "Idlib",
        "Rif Dimashq",
        "Tartus"
      ]
    },
    {
      "optionId": "Taiwan",
      "code": "TW",
      "subOptions": [
        "Changhua",
        "Chiayi",
        "Hsinchu",
        "Hualien",
        "Kaohsiung",
        "Keelung",
        "Kinmen",
        "Lienchiang",
        "Miaoli",
        "Nantou",
        "Penghu",
        "New Taipei",
        "Pingtung",
        "Taichung",
        "Tainan",
        "Taipei",
        "Taitung",
        "Taoyuan",
        "Yilan",
        "Yunlin"
      ]
    },
    {
      "optionId": "Tajikistan",
      "code": "TJ",
      "subOptions": [
        "Dushanbe",
        "Kŭhistoni Badakhshon",
        "Khatlon",
        "Sughd"
      ]
    },
    {
      "optionId": "Tanzania",
      "code": "TZ",
      "subOptions": [
        "Arusha",
        "Coast",
        "Dar es Salaam",
        "Dodoma",
        "Iringa",
        "Kagera",
        "Kigoma",
        "Kilimanjaro",
        "Lindi",
        "Manyara",
        "Mara",
        "Mbeya",
        "Morogoro",
        "Mtwara",
        "Mwanza",
        "Pemba North",
        "Pemba South",
        "Rukwa",
        "Ruvuma",
        "Shinyanga",
        "Singida",
        "Tabora",
        "Tanga",
        "Zanzibar North",
        "Zanzibar Central/South",
        "Zanzibar Urban/West"
      ]
    },
    {
      "optionId": "Thailand",
      "code": "TH",
      "subOptions": [
        "Amnat Charoen",
        "Ang Thong",
        "Bueng Kan",
        "Buri Ram",
        "Chachoengsao",
        "Chai Nat",
        "Chaiyaphum",
        "Chanthaburi",
        "Chiang Mai",
        "Chiang Rai",
        "Chon Buri",
        "Chumphon",
        "Kalasin",
        "Kamphaeng Phet",
        "Kanchanaburi",
        "Khon Kaen",
        "Krabi",
        "Krung Thep Mahanakhon (Bangkok)",
        "Lampang",
        "Lamphun",
        "Loei",
        "Lop Buri",
        "Mae Hong Son",
        "Maha Sarakham",
        "Mukdahan",
        "Nakhon Nayok",
        "Nakhon Phathom",
        "Nakhon Phanom",
        "Nakhon Ratchasima",
        "Nakhon Sawan",
        "Nakhon Si Thammarat",
        "Nan",
        "Narathiwat",
        "Nong Bua Lam Phu",
        "Nong Khai",
        "Nonthaburi",
        "Pathum Thani",
        "Pattani",
        "Phangnga",
        "Phatthalung",
        "Phayao",
        "Phetchabun",
        "Phetchaburi",
        "Phichit",
        "Phitsanulok",
        "Phra Nakhon Si Ayutthaya",
        "Phrae",
        "Phuket",
        "Prachin Buri",
        "Prachuap Khiri Khan",
        "Ranong",
        "Ratchaburi",
        "Rayong",
        "Roi Et",
        "Sa Kaeo",
        "Sakon Nakhon",
        "Samut Prakan",
        "Samut Sakhon",
        "Samut Songkhram",
        "Saraburi",
        "Satun",
        "Sing Buri",
        "Si Sa ket",
        "Songkhla",
        "Sukhothai",
        "Suphan Buri",
        "Surat Thani",
        "Surin",
        "Tak",
        "Trang",
        "Trat",
        "Ubon Ratchathani",
        "Udon Thani",
        "Uthai Thani",
        "Uttaradit",
        "Yala",
        "Yasothon"
      ]
    },
    {
      "optionId": "Timor-Leste",
      "code": "TL",
      "subOptions": [
        "Aileu",
        "Ainaro",
        "Baucau",
        "Bobonaro",
        "Cova Lima",
        "Dili",
        "Ermera",
        "Lautem",
        "Liquica",
        "Manatuto",
        "Manufahi",
        "Oecussi",
        "Viqueque"
      ]
    },
    {
      "optionId": "Togo",
      "code": "TG",
      "subOptions": [
        "Centre",
        "Kara",
        "Maritime",
        "Plateaux",
        "Savannes"
      ]
    },
    {
      "optionId": "Tokelau",
      "code": "TK",
      "subOptions": [
        "Atafu",
        "Fakaofo",
        "Nukunonu"
      ]
    },
    {
      "optionId": "Tonga",
      "code": "TO",
      "subOptions": [
        "'Eua",
        "Ha'apai",
        "Niuas",
        "Tongatapu",
        "Vava'u"
      ]
    },
    {
      "optionId": "Trinidad and Tobago",
      "code": "TT",
      "subOptions": [
        "Arima",
        "Chaguanas",
        "Couva-Tabaquite-Talparo",
        "Diefo Martin",
        "Mayaro-Rio Claro",
        "Penal-Debe",
        "Point Fortin",
        "Port-of-Spain",
        "Princes Town",
        "San Fernando",
        "San Juan-Laventille",
        "Sangre Grande",
        "Siparia",
        "Tobago",
        "Tunapuna-Piarco"
      ]
    },
    {
      "optionId": "Tunisia",
      "code": "TN",
      "subOptions": [
        "Ariana",
        "Beja",
        "Ben Arous",
        "Bizerte",
        "Gabes",
        "Gafsa",
        "Jendouba",
        "Kairouan",
        "Kasserine",
        "Kebili",
        "Kef",
        "Mahdia",
        "Medenine",
        "Monastir",
        "Nabeul",
        "Sfax",
        "Sidi Bouzid",
        "Siliana",
        "Sousse",
        "Tataouine",
        "Tozeur",
        "Tunis",
        "Zaghouan"
      ]
    },
    {
      "optionId": "Turkey",
      "code": "TR",
      "subOptions": [
        "Adana", "Adıyaman", "Afyonkarahisar", "Aksaray", "Amasya", "Ankara", "Antalya", "Ardahan", "Artvin", "Aydın", "Ağrı", "Balıkesir", "Bartın", "Batman", "Bayburt", "Bilecik", "Bingöl", "Bitlis", "Bolu", "Burdur", "Bursa", "Denizli", "Diyarbakır", "Düzce", "Edirne", "Elazığ", "Erzincan", "Erzurum", "Eskişehir", "Gaziantep", "Giresun", "Gümüşhane", "Hakkâri", "Hatay", "Isparta", "Iğdır", "Kahramanmaraş", "Karabük", "Karaman", "Kars", "Kastamonu", "Kayseri", "Kilis", "Kocaeli", "Konya", "Kütahya", "Kırklareli", "Kırıkkale", "Kırşehir", "Malatya", "Manisa", "Mardin", "Mersin", "Muğla", "Muş", "Nevşehir", "Niğde", "Ordu", "Osmaniye", "Rize", "Sakarya", "Samsun", "Siirt", "Sinop", "Sivas", "Tekirdağ", "Tokat", "Trabzon", "Tunceli", "Uşak", "Van", "Yalova", "Yozgat", "Zonguldak", "Çanakkale", "Çankırı", "Çorum", "İstanbul", "İzmir", "Şanlıurfa", "Şırnak"
      ]
    },
    {
      "optionId": "Turkmenistan",
      "code": "TM",
      "subOptions": [
        "Ahal",
        "Asgabat",
        "Balkan",
        "Dashoguz",
        "Lebap",
        "Mary"
      ]
    },
    {
      "optionId": "Turks and Caicos Islands",
      "code": "TC",
      "subOptions": [
        "Turks and Caicos Islands"
      ]
    },
    {
      "optionId": "Tuvalu",
      "code": "TV",
      "subOptions": [
        "Funafuti",
        "Nanumanga",
        "Nanumea",
        "Niutao",
        "Nui",
        "Nukufetau",
        "Nukulaelae",
        "Vaitupu"
      ]
    },
    {
      "optionId": "Uganda",
      "code": "UG",
      "subOptions": [
        "Abim",
        "Adjumani",
        "Amolatar",
        "Amuria",
        "Amuru",
        "Apac",
        "Arua",
        "Budaka",
        "Bududa",
        "Bugiri",
        "Bukedea",
        "Bukwa",
        "Buliisa",
        "Bundibugyo",
        "Bushenyi",
        "Busia",
        "Butaleja",
        "Dokolo",
        "Gulu",
        "Hoima",
        "Ibanda",
        "Iganga",
        "Isingiro",
        "Jinja",
        "Kaabong",
        "Kabale",
        "Kabarole",
        "Kaberamaido",
        "Kalangala",
        "Kaliro",
        "Kampala",
        "Kamuli",
        "Kamwenge",
        "Kanungu",
        "Kapchorwa",
        "Kasese",
        "Katakwi",
        "Kayunga",
        "Kibaale",
        "Kiboga",
        "Kiruhura",
        "Kisoro",
        "Kitgum",
        "Koboko",
        "Kotido",
        "Kumi",
        "Kyenjojo",
        "Lira",
        "Luwero",
        "Lyantonde",
        "Manafwa",
        "Maracha",
        "Masaka",
        "Masindi",
        "Mayuge",
        "Mbale",
        "Mbarara",
        "Mityana",
        "Moroto",
        "Moyo",
        "Mpigi",
        "Mubende",
        "Mukono",
        "Nakapiripirit",
        "Nakaseke",
        "Nakasongola",
        "Namutumba",
        "Nebbi",
        "Ntungamo",
        "Oyam",
        "Pader",
        "Pallisa",
        "Rakai",
        "Rukungiri",
        "Sembabule",
        "Sironko",
        "Soroti",
        "Tororo",
        "Wakiso",
        "Yumbe"
      ]
    },
    {
      "optionId": "Ukraine",
      "code": "UA",
      "subOptions": [
        "Avtonomna Respublika Krym",
        "Cherkas'ka Oblast",
        "Chernihivs'ka Oblast",
        "Chernivets'ka Oblast",
        "Dnipropetrovs'ka Oblast",
        "Donets'ka Oblast",
        "Ivano-Frankivs'ka Oblast",
        "Kharkivs'ka Oblast",
        "Khersons'ka Oblast",
        "Khmel'nyts'ka Oblast",
        "Kirovohrads'ka Oblast",
        "Kyïv",
        "Kyïvs'ka Oblast",
        "L'vivs'ka Oblast",
        "Luhans'ka Oblast",
        "Mykolaïvs'ka Oblast",
        "Odes'ka Oblast",
        "Poltavs'ka Oblast",
        "Rivnens'ka Oblast",
        "Sevastopol",
        "Sums'ka Oblast",
        "Ternopil's'ka Oblast",
        "Vinnyts'ka Oblast",
        "Volyns'ka Oblast",
        "Zakarpats'ka Oblast",
        "Zaporiz'ka Oblast",
        "Zhytomyrs'ka Oblast"
      ]
    },
    {
      "optionId": "United Arab Emirates",
      "code": "AE",
      "subOptions": [
        "Abu Dhabi",
        "Ajman",
        "Dubai",
        "Fujairah",
        "Ras al Khaimah",
        "Sharjah",
        "Umm Al Quwain"
      ]
    },
    {
      "optionId": "United Kingdom",
      "code": "GB",
      "subOptions": [
        "Aberdeen City",
        "Aberdeenshire",
        "Angus",
        "Antrim and Newtownabbey",
        "Ards and North Down",
        "Argyll and Bute",
        "Armagh City, Banbridge and Craigavon",
        "Barking and Dagenham",
        "Barnet",
        "Barnsley",
        "Bath and North East Somerset",
        "Bedford",
        "Belfast City",
        "Berkshire",
        "Bexley",
        "Birmingham",
        "Blackburn with Darwen",
        "Blackpool",
        "Blaenau Gwent",
        "Bolton",
        "Bournemouth, Christchurch and Poole",
        "Bracknell Forest",
        "Bradford",
        "Brent",
        "Bridgend",
        "Brighton and Hove",
        "Bristol, City of",
        "Bromley",
        "Buckinghamshire",
        "Bury",
        "Caerphilly",
        "Calderdale",
        "Cambridgeshire",
        "Camden",
        "Cardiff",
        "Carmarthenshire",
        "Causeway Coast and Glens",
        "Central Bedfordshire",
        "Ceredigion",
        "Cheshire East",
        "Cheshire West and Chester",
        "Clackmannanshire",
        "Conwy",
        "Cornwall",
        "Coventry",
        "Croydon",
        "Cumbria",
        "Darlington",
        "Denbighshire",
        "Derby",
        "Derbyshire",
        "Derry and Strabane",
        "Devon",
        "Doncaster",
        "Dorset",
        "Dudley",
        "Dumfries and Galloway",
        "Dundee City",
        "Durham, County",
        "Ealing",
        "East Ayrshire",
        "East Dunbartonshire",
        "East Lothian",
        "East Renfrewshire",
        "East Riding of Yorkshire",
        "East Sussex",
        "Edinburgh, City of",
        "Eilean Siar",
        "Enfield",
        "Essex",
        "Falkirk",
        "Fermanagh and Omagh",
        "Fife",
        "Flintshire",
        "Gateshead",
        "Glasgow City",
        "Gloucestershire",
        "Greenwich",
        "Gwynedd",
        "Hackney",
        "Halton",
        "Hammersmith and Fulham",
        "Hampshire",
        "Haringey",
        "Harrow",
        "Hartlepool",
        "Havering",
        "Herefordshire",
        "Hertfordshire",
        "Highland",
        "Hillingdon",
        "Hounslow",
        "Inverclyde",
        "Isle of Anglesey",
        "Isle of Wight",
        "Isles of Scilly",
        "Islington",
        "Kensington and Chelsea",
        "Kent",
        "Kingston upon Hull",
        "Kingston upon Thames",
        "Kirklees",
        "Knowsley",
        "Lambeth",
        "Lancashire",
        "Leeds",
        "Leicester",
        "Leicestershire",
        "Lewisham",
        "Lincolnshire",
        "Lisburn and Castlereagh",
        "Liverpool",
        "London, City of",
        "Luton",
        "Manchester",
        "Medway",
        "Merthyr Tydfil",
        "Merton",
        "Mid and East Antrim",
        "Mid-Ulster",
        "Middlesbrough",
        "Midlothian",
        "Milton Keynes",
        "Monmouthshire",
        "Moray",
        "Neath Port Talbot",
        "Newcastle upon Tyne",
        "Newham",
        "Newport",
        "Newry, Mourne and Down",
        "Norfolk",
        "North Ayrshire",
        "North East Lincolnshire",
        "North Lanarkshire",
        "North Lincolnshire",
        "North Somerset",
        "North Tyneside",
        "North Yorkshire",
        "Northamptonshire",
        "Northumberland",
        "Nottingham",
        "Nottinghamshire",
        "Oldham",
        "Orkney Islands",
        "Oxfordshire",
        "Pembrokeshire",
        "Perth and Kinross",
        "Peterborough",
        "Plymouth",
        "Portsmouth",
        "Powys",
        "Reading",
        "Redbridge",
        "Redcar and Cleveland",
        "Renfrewshire",
        "Rhondda Cynon Taff",
        "Richmond upon Thames",
        "Rochdale",
        "Rotherham",
        "Rutland",
        "Salford",
        "Sandwell",
        "Scottish Borders",
        "Sefton",
        "Sheffield",
        "Shetland Islands",
        "Shropshire",
        "Slough",
        "Solihull",
        "Somerset",
        "South Ayrshire",
        "South Gloucestershire",
        "South Lanarkshire",
        "South Tyneside",
        "Southampton",
        "Southend-on-Sea",
        "Southwark",
        "St. Helens",
        "Staffordshire",
        "Stirling",
        "Stockport",
        "Stockton-on-Tees",
        "Stoke-on-Trent",
        "Suffolk",
        "Sunderland",
        "Surrey",
        "Sutton",
        "Swansea",
        "Swindon",
        "Tameside",
        "Telford and Wrekin",
        "Thurrock",
        "Torbay",
        "Torfaen",
        "Tower Hamlets",
        "Trafford",
        "Vale of Glamorgan, The",
        "Wakefield",
        "Walsall",
        "Waltham Forest",
        "Wandsworth",
        "Warrington",
        "Warwickshire",
        "West Berkshire",
        "West Dunbartonshire",
        "West Lothian",
        "West Sussex",
        "Westminster",
        "Wigan",
        "Wiltshire",
        "Windsor and Maidenhead",
        "Wirral",
        "Wokingham",
        "Wolverhampton",
        "Worcestershire",
        "Wrexham",
        "York"
      ]
    },
    {
      "optionId": "United States",
      "code": "US",
      "subOptions":
        ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada", "New Hampshire", "New Jersey", "New Mexico", "New York", "North Carolina", "North Dakota", "Ohio", "Oklahoma", "Oregon", "Pennsylvania", "Rhode Island", "South Carolina", "South Dakota", "Tennessee", "Texas", "Utah", "Vermont", "Virginia", "Washington", "West Virginia", "Wisconsin", "Wyoming"]
    },
    {
      "optionId": "United States Minor Outlying Islands",
      "code": "UM",
      "subOptions": [
        "Baker Island",
        "Howland Island",
        "Jarvis Island",
        "Johnston Atoll",
        "Kingman Reef",
        "Midway Islands",
        "Navassa Island",
        "Palmyra Atoll",
        "Wake Island",
        "Bajo Nuevo Bank",
        "Serranilla Bank"
      ]
    },
    {
      "optionId": "Uruguay",
      "code": "UY",
      "subOptions": [
        "Artigas",
        "Canelones",
        "Cerro Largo",
        "Colonia",
        "Durazno",
        "Flores",
        "Florida",
        "Lavalleja",
        "Maldonado",
        "Montevideo",
        "Paysandú",
        "Río Negro",
        "Rivera",
        "Rocha",
        "Salto",
        "San José",
        "Soriano",
        "Tacuarembó",
        "Treinta y Tres"
      ]
    },
    {
      "optionId": "Uzbekistan",
      "code": "UZ",
      "subOptions": [
        "Toshkent shahri",
        "Andijon",
        "Buxoro",
        "Farg‘ona",
        "Jizzax",
        "Namangan",
        "Navoiy",
        "Qashqadaryo (Qarshi)",
        "Samarqand",
        "Sirdaryo (Guliston)",
        "Surxondaryo (Termiz)",
        "Toshkent wiloyati",
        "Xorazm (Urganch)",
        "Qoraqalpog‘iston Respublikasi (Nukus)"
      ]
    },
    {
      "optionId": "Vanuatu",
      "code": "VU",
      "subOptions": [
        "Malampa",
        "Pénama",
        "Sanma",
        "Shéfa",
        "Taféa",
        "Torba"
      ]
    },
    {
      "optionId": "Venezuela, Bolivarian Republic of",
      "code": "VE",
      "subOptions": [
        "Dependencias Federales",
        "Distrito Federal",
        "Amazonas",
        "Anzoátegui",
        "Apure",
        "Aragua",
        "Barinas",
        "Bolívar",
        "Carabobo",
        "Cojedes",
        "Delta Amacuro",
        "Falcón",
        "Guárico",
        "Lara",
        "Mérida",
        "Miranda",
        "Monagas",
        "Nueva Esparta",
        "Portuguesa",
        "Sucre",
        "Táchira",
        "Trujillo",
        "Vargas",
        "Yaracuy",
        "Zulia"
      ]
    },
    {
      "optionId": "Vietnam",
      "code": "VN",
      "subOptions": [
        "An Giang",
        "Bà Rịa - Vũng Tàu",
        "Bình Dương",
        "Bình Phước",
        "Bình Định",
        "Bình Thuận",
        "Bạc Liêu",
        "Bắc Giang",
        "Bắc Kạn",
        "Bắc Ninh",
        "Bến Tre",
        "Cao Bằng",
        "Cà Mau",
        "Đắk Lắk",
        "Đắk Nông",
        "Điện Biên",
        "Đồng Nai",
        "Đồng Tháp",
        "Gia Lai",
        "Hà Giang",
        "Hà Nam",
        "Hà Tây",
        "Hà Tĩnh",
        "Hải Dương",
        "Hậu Giang",
        "Hòa Bình",
        "Hưng Yên",
        "Khánh Hòa",
        "Kiên Giang",
        "Kon Tum",
        "Lai Châu",
        "Lâm Đồng",
        "Lạng Sơn",
        "Lào Cai",
        "Long An",
        "Nam Định",
        "Nghệ An",
        "Ninh Bình",
        "Ninh Thuận",
        "Phú Thọ",
        "Phú Yên",
        "Quảng Bình",
        "Quảng Nam",
        "Quảng Ngãi",
        "Quảng Ninh",
        "Quảng Trị",
        "Sóc Trăng",
        "Sơn La",
        "Tây Ninh",
        "Thái Bình",
        "Thái Nguyên",
        "Thanh Hóa",
        "Thừa Thiên–Huế",
        "Tiền Giang",
        "Trà Vinh",
        "Tuyên Quang",
        "Vĩnh Long",
        "Vĩnh Phúc",
        "Yên Bái",
        "Cần Thơ",
        "Đà Nẵng",
        "Hà Nội",
        "Hải Phòng",
        "Hồ Chí Minh (Sài Gòn)"
      ]
    },
    {
      "optionId": "Virgin Islands, British",
      "code": "VG",
      "subOptions": [
        "Anegada",
        "Jost Van Dyke",
        "Tortola",
        "Virgin Gorda"
      ]
    },
    {
      "optionId": "Virgin Islands, U.S.",
      "code": "VI",
      "subOptions": [
        "St. Thomas",
        "St. John",
        "St. Croix"
      ]
    },
    {
      "optionId": "Wallis and Futuna",
      "code": "WF",
      "subOptions": [
        "Alo",
        "Sigave",
        "Wallis"
      ]
    },
    {
      "optionId": "Western Sahara",
      "code": "EH",
      "subOptions": [
        "Es Smara",
        "Boujdour",
        "Laâyoune",
        "Aousserd",
        "Oued ed Dahab"
      ]
    },
    {
      "optionId": "Yemen",
      "code": "YE",
      "subOptions": [
        "Abyān",
        "'Adan",
        "Aḑ Ḑāli'",
        "Al Bayḑā'",
        "Al Ḩudaydah",
        "Al Jawf",
        "Al Mahrah",
        "Al Maḩwīt",
        "'Amrān",
        "Dhamār",
        "Ḩaḑramawt",
        "Ḩajjah",
        "Ibb",
        "Laḩij",
        "Ma'rib",
        "Raymah",
        "Şā‘dah",
        "Şan‘ā'",
        "Shabwah",
        "Tā‘izz"
      ]
    },
    {
      "optionId": "Zambia",
      "code": "ZM",
      "subOptions": [
        "Central",
        "Copperbelt",
        "Eastern",
        "Luapula",
        "Lusaka",
        "Muchinga",
        "Northern",
        "North-Western",
        "Southern",
        "Western"
      ]
    },
    {
      "optionId": "Zimbabwe",
      "code": "ZW",
      "subOptions": [
        "Bulawayo",
        "Harare",
        "Manicaland",
        "Mashonaland Central",
        "Mashonaland East",
        "Mashonaland West",
        "Masvingo",
        "Matabeleland North",
        "Matabeleland South",
        "Midlands"
      ]
    }
  ],
  stateName: "searchCounty",
};
